<coreui-loading-indicator label="Working..."></coreui-loading-indicator>

<div class="container">
    <div class="left-container">
        <div class="hasBreadcrumb">
            <coreui-breadcrumbs>
                <a href="javascript:void(0)" (click)="goToManageTrackedAttributes()" coreUIBreadcrumbLink>Tracked
                    Attribute Sets</a>
                <span coreUIBreadcrumbSlash></span>
                <a href="javascript:void(0)" coreUIBreadcrumbEnd>
                    {{
                    currentTrackedAttribute
                    ? currentTrackedAttribute.templateName
                    : "Create Attribute Set"
                    }}</a>
            </coreui-breadcrumbs>
        </div>

        <div *ngIf="currentTrackedAttribute">
            <div class="edit-tracked-attribute-container">
                <h1>{{ trackedAttributeForm.get("attributeSetName").value }}</h1>
            </div>

            <div class="edit-tracked-attribute-details">
                <div class="edit-tracked-attribute-detail">
                    <div class="text-style-2">Created:</div>
                    <div class="text-style-3">
                        {{
                        currentTrackedAttribute.createdDate
                        | date: "MM/dd/YY hh:mm a":"UTC"
                        }}
                        {{ "By " + currentTrackedAttribute.createdBy }}
                    </div>
                </div>
                <div class="edit-tracked-attribute-detail">
                    <div class="text-style-2">Last Updated:</div>
                    <div class="text-style-3">
                        {{
                        currentTrackedAttribute.lastModifiedDate
                        | date: "MM/dd/YY hh:mm a":"UTC"
                        }}
                        {{ "By " + currentTrackedAttribute.lastModifiedBy }}
                    </div>
                </div>
                <div class="edit-tracked-attribute-detail">
                    <div class="text-style-2">Data Attribute Type:</div>
                    <div class="data-type-container">
                        <i coreUIIcon="table"></i>
                        <div class="text-style-2">
                            {{
                            currentTrackedAttribute.audienceType === "PROFILE"
                            ? "Profile"
                            : "Prospect"
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!currentTrackedAttribute" class="steper">
            <coreui-step-wizard wizardStyle="ordered" #orderedWizard>
                <coreui-step [status]="currentStep === 'chooseAttributes' ? 'verified' : 'enabled'"
                    label="1. Define Your Attribute Set" #orderedFirstStep [active]="true"></coreui-step>
                <coreui-step [status]="currentStep === 'chooseAttributes' ? 'enabled' : 'disabled'"
                    label="2. Select Your Attributes" #orderedLastStep></coreui-step>
            </coreui-step-wizard>
        </div>

        <ng-container *ngIf="currentStep === 'defineAttributeSet'">
            <ng-container *ngTemplateOutlet="defineAttributeSetStep"></ng-container>
        </ng-container>

        <ng-container *ngIf="currentStep === 'chooseAttributes'">
            <ng-container *ngTemplateOutlet="chooseAttributesStep"></ng-container>
        </ng-container>
    </div>

    <div class="right-container">
        <ng-container *ngTemplateOutlet="selectAttributeDrawer"></ng-container>
    </div>
</div>

<div class="bottom-button-container">
    <button *ngIf="!currentTrackedAttribute" (click)="goBack()" class="bottom-button" type="button"
        coreUIButton="icon-large" [disabled]="false" withBorder>
        <i coreUIIcon="arrowLeft"></i>
    </button>
    <button *ngIf="trackedAttributeForm && !hasPendingTrackedAttributes" (click)="onNextStep()" class="bottom-button"
        type="button" coreUIButton="primary" [disabled]="false">
        {{ currentStep === "defineAttributeSet" ? "Next" : "Save Attribute Set" }}
    </button>
    <button *ngIf="trackedAttributeForm && !currentTrackedAttribute" (click)="resetTrackedAttributeForm()"
        class="bottom-button" type="button" coreUIButton="text-primary" [disabled]="false">
        Discard
    </button>
</div>

<ng-template #defineAttributeSetStep>
    <div class="attribute-set-container">
        <div class="attribute-set-title">
            <h2>What would you like to name your attribute set?</h2>
        </div>
        <div class="attribute-set-container-desc">
            <p class="text-style-3">
                Let's give your new attribute set a unique name that makes it easy to
                identify, Don't worry, you will be able to change the name in the
                future.
            </p>
        </div>
        <div *ngIf="trackedAttributeForm" class="attribute-set-name-input-container">
            <form [formGroup]="trackedAttributeForm" coreUIForm>
                <coreui-form-field label="Attribute Set Name">
                    <input formControlName="attributeSetName" coreUIInput
                        placeholder="Ex. Fall Fashion Attribute Set" />
                    <coreui-form-error *ngIf="
              trackedAttributeForm.get('attributeSetName')?.errors?.required
            ">
                        Attribute Set Name is required.</coreui-form-error>
                    <coreui-form-error *ngIf="
              trackedAttributeForm.get('attributeSetName')?.errors
                ?.hasAttributeSetNameAlready
            ">
                        This name is already in use.</coreui-form-error>
                </coreui-form-field>
            </form>
        </div>
    </div>

    <div class="attribute-set-container">
        <div class="attribute-set-title">
            <h2>What type of data attributes will this set use?</h2>
        </div>
        <div class="attribute-set-container-desc">
            <p class="text-style-3">
                Select the type of data attributes you want to include in this set.
            </p>
        </div>
        <div *ngIf="trackedAttributeForm" class="attribute-set-input-container">
            <coreui-form-tab-group (tabValue)="onAttributeSetTypeTabValueChange($event)" cardType="small">
                <coreui-form-tab [active]="
            trackedAttributeForm.get('attributeSetType').value === 'Profile'
          " label="Profile"
                    subHeader="Attribues about your known audience, such as their preferences, behaviors, or demographics."></coreui-form-tab>
                <coreui-form-tab *ngIf="isProspectEnabled" [active]="
            trackedAttributeForm.get('attributeSetType').value === 'Prospect'
          " label="Prospect"
                    subHeader="Attributes about potential customers to help you find and engage new audiences."></coreui-form-tab>
            </coreui-form-tab-group>
        </div>
    </div>
</ng-template>

<ng-template #chooseAttributesStep>
    <div class="attribute-set-container">
        <div class="attribute-set-title">
            <h2>
                {{
                currentTrackedAttribute
                ? "Add or edit the attributes you want to track"
                : "Choose the attributes you
                want to track"
                }}
            </h2>
        </div>
        <div class="attribute-set-container-desc">
            <p *ngIf="currentTrackedAttribute" class="text-style-3">
                You can track up to 20 key attributes from your Profile data.
            </p>
            <p *ngIf="!currentTrackedAttribute" class="text-style-3">
                You can track up to 20 key attributes from your Profile data. To begin,
                click "<b>Track an Attribute</b>" below.
            </p>
        </div>
        <coreui-notification *ngIf="hasPendingTrackedAttributes" notificationStyle="clock" [isPersistent]="true"
            [autoShow]="true">
            This attribute set cannot be edited or deleted while updates are in progress.
        </coreui-notification>
        <div *ngIf="showSelectAttributeError" class="selected-attribute-error-container">
            <i coreUIIcon="errorShield"></i>
            You must include at least one attribute
        </div>
        <div class="total-attribute-container">
            <div class="total-attribute-icon-container">
                <div class="total-attribute-count-container">
                    <div class="summary-icon-container">
                        <i coreUIIcon="summary"></i>
                    </div>
                    <h4>
                        Total Tracked Attributes: {{ selectedAttributeNodes.length }}/20
                    </h4>
                </div>
                <div class="show-full-path">
                    <span class="text-style-3">Show full path</span>
                    <form [formGroup]="attributeSearchForm">
                        <coreui-toggle formControlName="showFullPath"></coreui-toggle>
                    </form>
                </div>
            </div>
            <div class="selected-attributes-container" *ngIf="selectedAttributeNodes.length">
                <ng-container *ngTemplateOutlet="displaySelectedAttributes"></ng-container>
            </div>
            <button *ngIf="!hasPendingTrackedAttributes" [disabled]="selectedAttributeNodes.length >= 20"
                (click)="onShowSelectAttributeDrawer()" type="button" coreUIButton="primary" withIcon="left">
                <i coreUIIcon="summary"></i>
                Track an Attribute
            </button>
        </div>
    </div>
</ng-template>

<ng-template #displaySelectedAttributes>
    @for(node of selectedAttributeNodes; track node.cabId) {
    <div class="drop-list drop-list-search">
        <div class="drag-element drag-element-search">
            <lib-type-icon [type]="node.dataType"></lib-type-icon>
            <span class="search-text-overflow node-content selected-attribute">
                <span [innerHtml]="node.displayName"></span>
                <span class="full-path-text text-style-5"
                    *ngIf="attributeSearchForm.get('showFullPath').value && node.fullPath">
                    {{ node.fullPath }}
                </span>
            </span>
            <span *ngIf="hasPendingTrackedAttributes">
                <coreui-badge [color]="node.status === 'pending' ? 'Pineapple' : 'Lime'" [border]="false">{{node.status
                    | titlecase}}</coreui-badge>
            </span>
            <span *ngIf="!hasPendingTrackedAttributes" (click)="removeNode(node)">
                <i coreUIIcon="trash" scale="1.5"></i>
            </span>
        </div>
    </div>
    }
</ng-template>

<ng-template #selectAttributeDrawer>
    <coreui-drawer [(visible)]="showSelectAttributeDrawer" position="right">
        <div class="drawer-content--inside" [ngClass]="{ disableNodeClick: loadingNodes }">
            <h3>Select an attribute from your profile data</h3>

            <div *ngIf="breadcrumbs && breadcrumbs.length && breadcrumbs.length > 1" class="search-container">
                <ng-container *ngTemplateOutlet="searchAttributes"></ng-container>
            </div>

            <div *ngIf="
          breadcrumbs &&
          breadcrumbs.length &&
          breadcrumbs.length >= 2 &&
          !showSearchTree
        " class="breadcrumbs-container">
                <ng-container *ngTemplateOutlet="breadcrumbsContainer"></ng-container>
            </div>

            <span *ngIf="showSearchLimit && breadcrumbs.length > 1" class="text-style-error CharSearch">Enter a minimum
                of 3 characters to begin search</span>

            <div *ngIf="showSearchTree">
                <div class="searchResultInfoBar">
                    <span *ngIf="attributeOptions && attributeOptions.length !== 0" class="text-style-5">{{
                        attributeOptions.length }} result<span *ngIf="attributeOptions.length !== 1">s</span></span>
                    <span *ngIf="attributeOptions && attributeOptions.length !== 0"
                        class="text-style-muted-5 dot">|</span>
                    <a href="javascript:void(0)" (click)="resetAttributeSearch()" class="text-style-5">Clear search</a>
                </div>
            </div>

            <coreui-scroll-indicator>
                <div coreUIScrollIndicatorTarget class="picker-nav-container">
                    <ng-container *ngIf="!attributeLoader && attributeOptions.length">
                        <ng-container *ngTemplateOutlet="
                showSearchTree ? searchResult : nonSearchResult
              "></ng-container>
                    </ng-container>
                    <div *ngIf="attributeLoader" class="loading-indicator">
                        <span>
                            <coreui-load-mask [active]="true"> </coreui-load-mask>
                        </span>
                    </div>
                    <coreui-graphic-message graphic="noResults" label="No Results Found" *ngIf="
              !attributeLoader && !attributeOptions.length && !showSearchLimit
            ">
                        Sorry, we did not find any results for your search.
                    </coreui-graphic-message>
                </div>
            </coreui-scroll-indicator>
        </div>
    </coreui-drawer>
</ng-template>

<ng-template #searchAttributes>
    <form [formGroup]="attributeSearchForm">
        <div class="search-inner-container">
            <input name="search" placeholder="Search" formControlName="attributeSearchFormControl" type="text"
                coreUIInput />
            <button (click)="onSearchUpdated()" coreUIButton>
                <i coreUIIcon="search" scale="1.6"></i>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #nonSearchResult>
    <!-- Show Folder -->
    <div *ngFor="let node of attributeOptions">
        <span *ngIf="node.dataSetType !== 'DATA_SET_ATTRIBUTE'">
            <coreui-tree-progressive-pane (activeChange)="onAttributeOptionClicked(node)"
                [header]="node.displayName"></coreui-tree-progressive-pane>
        </span>
    </div>

    <!-- Show Nodes -->
    @for(node of attributeOptions; track node.cabId) {
    <ng-container *ngIf="node.dataSetType === 'DATA_SET_ATTRIBUTE'">
        <ng-container *ngTemplateOutlet="nodeTemplate; context:{node}"></ng-container>
    </ng-container>
    }
</ng-template>

<ng-template #searchResult>
    <ng-container *ngIf="isRegularSearch">
        <ng-container *ngTemplateOutlet="nonSearchResult"></ng-container>
    </ng-container>
    <ng-container *ngIf="!isRegularSearch">
        <div *ngFor="let node of attributeOptions; index as nodeIdx">
            <coreui-breadcrumbs>
                <ng-container *ngFor="let path of node['path']; index as idx">
                    <span *ngIf="idx > 1" coreUIBreadcrumbLink [disabled]="true">
                        {{ path.displayName }}</span>
                    <span *ngIf="idx > 1" coreUIBreadcrumbSlash></span>
                </ng-container>
                <span coreUIBreadcrumbEnd>{{ node["displayName"] }}</span>
            </coreui-breadcrumbs>
            <div class="drop-list drop-list-search" coreUIDropList *ngFor="let child of node.children" [data]="child">
                <div class="drag-element drag-element-search">
                    <lib-type-icon [type]="child.dataType"></lib-type-icon>
                    <span class="search-text-overflow node-content"
                        [innerHtml]="child['displayName'] | highlighter: searchText"></span>
                    <span (click)="addNode(child)" class="add-attribute-button text-style-link">Add</span>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #nodeTemplate let-node="node">
    <ng-container *ngIf="node">
        <div class="drop-list drop-list-search">
            <div class="drag-element drag-element-search">
                <lib-type-icon [type]="node.dataType"></lib-type-icon>
                <span class="search-text-overflow node-content" [innerHtml]="node.displayName"></span>
                <span (click)="addNode(node)" class="add-attribute-button text-style-link">Add</span>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #breadcrumbsContainer>
    <coreui-breadcrumbs breadcrumbStyle="primary" [includeBottomMargin]="false">
        <a href="javascript:void(0)" (click)="onBreadcrumbClicked(0)" coreUIBreadcrumbLink>{{ breadcrumbs[0].displayName
            }}</a>
        <span coreUIBreadcrumbSlash></span>
        <ng-container *ngIf="breadcrumbs.length > 2">
            <coreui-menu coreUIBreadcrumbMenu menuButtonStyle="link" icon="ellipsis" menuAlignment="left"
                showMenuAction="hover">
                <coreui-menu-section>
                    <div *ngFor="let breadcrumb of breadcrumbs; let i = index">
                        <button (click)="onBreadcrumbClicked(i)" *ngIf="i !== 0 && i < breadcrumbs.length - 1"
                            type="button" coreUIMenuItem>
                            {{ breadcrumb.displayName }}
                        </button>
                    </div>
                </coreui-menu-section>
            </coreui-menu>
            <span coreUIBreadcrumbSlash></span>
        </ng-container>
        <span coreUIBreadcrumbEnd>{{
            breadcrumbs[breadcrumbs.length - 1].displayName
            }}</span>
    </coreui-breadcrumbs>
</ng-template>