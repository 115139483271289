import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {  UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    BehaviorSubject,
    combineLatest as observableCombineLatest,
} from 'rxjs';
import {
  take,
  filter,
} from 'rxjs/operators';

import { SaveAudienceDefinition } from '../audience/audience.actions';
import {
  SetPrebuiltAudience,
} from '../audience-builder/audience-builder.actions';
import { AppState } from '../reducers';
import {selectContext, selectContextId} from '../context/context.reducer';
import { BuilderAudience, ERROR_NO_ATTRIBUTE, ERROR_NO_ATTRIBUTE_VALUE } from '../audience-builder/audience-builder.models';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { CountsService } from '../counts/counts.service';
import {
  convertAudienceBuilderToDefinition,
  convertAudienceDefinitionToBuilder,
  errorIncludes,
  getParsedApiErrorMessage,
} from '../audience/audience.utils';
import { fetchOutcome } from '../utils/fetch-state';
import { DataType } from '../enums/data-types';
import {
  DEFINITION_TXT,
  isDefined,
  BUILDER_TXT
} from '../utils/utils';
import { CabConstants } from '../cab.constants';
import { SetUnsavedChangesState } from '../hasUnsavedChanges/hasUnsavedChanges.actions';
import { UtilsService } from '../utils/utilservice';
import { FeatureService } from '../utils/feature-service';
import { FEATURE_ASSET_LISTING, FEATURE_TAGS_FILTERING } from '../utils/feature-utils';
import { AssetService, AssetType } from '@epsilon-cdp/pcm-common-lib';

@UntilDestroy()
@Component({
    selector: 'lib-audience-selection',
    templateUrl: './audience-selection.component.html',
    styleUrls: ['./audience-selection.component.sass']
})
export class AudienceSelectionComponent implements OnInit, OnChanges{
    errorOnLoadState$ = new BehaviorSubject<boolean>(false);
    error$ = new BehaviorSubject<string>('');
    contextId: string;
    dataUniverseId;
    isEdit: boolean;
    @Input()
    audienceForm: UntypedFormGroup;
    builderAudience: BuilderAudience;
    productType: string;
    isProductTypeDcdp: boolean;
    updateCountSuccess: boolean;
    errorMessage: string;
    isBuilderView:boolean;

    @ViewChild('errorNotification', { static: true }) public errorNotification;

    limitScroll = false;
    @ViewChild('audienceDefinitionScroll') audienceDefinitionScroll;

    @HostListener('window:scroll', ['$event'])
    scrollHander(){
      const offset = this.audienceDefinitionScroll.nativeElement.getBoundingClientRect();
      this.limitScroll = offset.top < 5;
    }

    constructor(
        public store: Store<AppState>,
        private router: Router,
        private route: ActivatedRoute,
        private actions$: Actions,
        public countsService: CountsService,
        public builderService: AudienceBuilderService,
        private utilsService: UtilsService,
        public featureService: FeatureService,
        private assetService: AssetService
    ) {
      this.store
        .select(selectContext)
        .pipe(untilDestroyed(this))
        .subscribe((context) => {
          this.productType = context?.productType;
          if(context?.productType === CabConstants.DCDP_PRODUCT_NAME) {
            this.isProductTypeDcdp = true;
          }
        });
        this.builderAudience = this.builderService.audience;
      observableCombineLatest([
        this.builderService.activeDataUniverseId$,
        this.store.select(selectContextId).pipe(filter(isDefined)),
      ])
        .pipe(untilDestroyed(this))
        .subscribe(([dataUniverseId, contextId]) => {
          this.contextId = contextId;
          this.dataUniverseId = dataUniverseId;
        });
        this.isBuilderView = this.router.url.includes(`${BUILDER_TXT}/view`)
    }

    ngOnInit(): void {
      const audienceDefinitionId =
            this.route.snapshot.paramMap?.get('definitionId');
        if (audienceDefinitionId) {
            this.isEdit = true;
        }
        this.builderService.audienceBuilderCriteriaManually$.subscribe(val => {
          if(val){
            this.store.dispatch(new SetUnsavedChangesState('pending'));
          } else {
            this.store.dispatch(new SetUnsavedChangesState(null));
          }
        });
        this.error$.pipe(untilDestroyed(this)).subscribe((message) => {
          if(message) {
            this.errorMessage = message;
            this.errorNotification.show();
          }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (changes['audienceForm'] && changes['audienceForm'].currentValue) {
        this.audienceForm = changes['audienceForm'].currentValue;
      }
    }

    isDedupeTypeValid(): boolean {
        return this.audienceForm?.get('dedupeType').valid;
    }

    handleInvalidDefinitionError() {
      const errorMsg = !this.builderService.definitionHasAttribute$.value
        ? ERROR_NO_ATTRIBUTE
        : !this.builderService.allExpressionsHaveValues$.value
        ? ERROR_NO_ATTRIBUTE_VALUE
        : 'Invalid User Input.';
      this.error$.next(errorMsg);
    }

    onUpdateTagSuccess(definition) {
      this.countsService
      .updateCount(definition)
      .pipe(take(1), untilDestroyed(this)).subscribe(
        (audience) => {
            this.resetFormUpdateBoolean();
            this.countsService.startCron(audience);
            this.countsService.saveDisable$.next(false);
            this.router.navigate([
              this.utilsService.getProductBaseUrl(this.router, this.route),
                audience.cabContextId,
                audience.dataUniverseId,
                'builder',
                'edit',
                audience.id,
              ] ,{state:{tab: 'criteria Selection'}});
              this.store.dispatch(new SetUnsavedChangesState(null));
              this.updateCountSuccess = true;
        },
        (error) => {
            // TODO: utilize backend error messages
            this.countsService.countUpdating$.next(false);
            if(errorIncludes(error?.error, 'already exists in')) {
             const  errorMsg = error?.error.errorDetails[0].errorMessage;
              this.error$.next(errorMsg);
            }else {
              this.error$.next(
                'Something went wrong updating counts. Please try again later.'
            );
            }
        }
    );
    }

    submitUpdateCount(countUpdated) {
      this.error$.next(null);
      this.updateCountSuccess = false;
        const definition = convertAudienceBuilderToDefinition(
            this.builderService.audience,
            this.contextId,
            this.dataUniverseId
        );
        definition.displayName = this.audienceForm.get('displayName').value;
        definition.description = this.audienceForm.get('description')?.value;
        definition.dedupeIdentityType = this.audienceForm.get('dedupeType').value;
        definition.businessUnitId = this.utilsService.getBusinessUnitId(this.route);
        if(definition.businessUnitId === 'DEFAULT'){
          definition.businessUnitId = null;
        }
        if(this.audienceForm.get('audienceQueryCaseInsensitive')) {
          
          definition.query.queryFlags.caseInsensitive = !this.audienceForm.get('audienceQueryCaseInsensitive').value;
        }
        if (this.productType === 'DCDP') {
          if(definition.audienceAttributes) {
            definition.audienceAttributes.channelType = this.audienceForm.get('channelType').value;
            definition.audienceAttributes.alternateKeyType = (definition.dedupeIdentityType === "AlternateKey" || definition.dedupeIdentityType === "GoldenAlternateKey") ? this.audienceForm.get('alternateKey').value : ''
          } else {
            definition.audienceAttributes = {
              channelType: this.audienceForm.get('channelType').value,
              alternateKeyType:  (definition.dedupeIdentityType === "AlternateKey" || definition.dedupeIdentityType === "GoldenAlternateKey") ? this.audienceForm.get('alternateKey').value : null
            };
          }
        }
        if (this.builderService.audienceBuilderUpdatedManually$.value || this.audienceForm.dirty) {
            this.store.dispatch(new SaveAudienceDefinition(definition));
            this.actions$
                .pipe(
                    fetchOutcome(SaveAudienceDefinition.type),
                    take(1),
                    untilDestroyed(this)
                ).subscribe(({ result }) => {
                  this.onSaveAudienceDefinitionSuccessForUpdateCount(countUpdated);
                  const definition = result.audienceDefinition;
                  this.setPrebuiltAudience(definition);
                  this.isEdit = true;
                  const selectedAssetTags = this.audienceForm.get('assetTags')?.value?.length;
                  const allowTagsEnabled = this.featureService.isFeatureEnabled(FEATURE_TAGS_FILTERING) && this.featureService.isFeatureEnabled(FEATURE_ASSET_LISTING) && this.productType ==='DCDP';
                  if(definition.id && allowTagsEnabled && selectedAssetTags){
                    this.associateTags(definition);
                  } else {
                    this.onUpdateTagSuccess(definition);
                  }
              }, (error) => {
                this.errorMessage = getParsedApiErrorMessage(error);
                this.errorNotification.show();
              });
        }
        else {
            definition.displayName = this.audienceForm.get('displayName').value;
            definition.description = this.audienceForm.get('description')?.value;
            definition.dedupeIdentityType = this.audienceForm.get('dedupeType').value;
           
            this.isEdit = true
            this.store.dispatch(new SaveAudienceDefinition(definition));
            this.actions$
                .pipe(
                    fetchOutcome(SaveAudienceDefinition.type),
                    take(1),
                    untilDestroyed(this)).subscribe(() => {
                      this.onSaveAudienceDefinitionSuccessForUpdateCount(countUpdated);
                      this.setPrebuiltAudience(definition);
                      this.countsService.updateCount(definition)
                      .pipe(take(1), untilDestroyed(this)).subscribe(res => {
                        this.countsService.startCron(res);
                        this.countsService.saveDisable$.next(false);
                      });
                    }, (error) => {
                      this.errorMessage = getParsedApiErrorMessage(error);
                      this.errorNotification.show();
                    }) 
        }
    }

    onSaveAudienceDefinitionSuccessForUpdateCount(countUpdated) {
      this.countsService.resetBuilderCounts();
      this.countsService.countUpdating$.next(true);
      this.countsService.saveDisable$.next(true);
      countUpdated.show();
    }

    setPrebuiltAudience(definition) {
        const audience = convertAudienceDefinitionToBuilder(definition, true);

        this.store.dispatch(
            new SetPrebuiltAudience(
                audience,
                this.contextId,
                DataType.AUDIENCE_DEFINITION,
                true
            )
        );
    }

    associateTags(definition) {
      const selectedAssetTags = this.audienceForm.get('assetTags')?.value;
      const assetTags$ = definition.id
        ? selectedAssetTags?.map((value) => ({
            namespace: '',
            tag: value,
          }))
        : [];
  
      const tagAssociationRequest = {
        assetId: definition.id,
        tags: assetTags$,
        type: AssetType.AUDIENCE_DEFINITION,
      };
  
      this.assetService
        .associateTags(tagAssociationRequest)
        .pipe(untilDestroyed(this))
        .subscribe(
          () => {
            this.onUpdateTagSuccess(definition);
          },
          (error) => {
            this.errorMessage = 'Something went wrong while adding tags!';
            if (error.error?.errorDetails) {
              this.errorMessage = error.error?.errorDetails[0]?.errorMessage;
            }
            this.errorNotification.show();
          }
        );
    }

    resetFormUpdateBoolean() {
        this.audienceForm?.markAsPristine();
        this.audienceForm?.markAsUntouched();
        this.builderService.audienceBuilderUpdatedManually$.next(false);
        this.builderService.audienceBuilderCriteriaManually$.next(false);

    }

    cancel() {
        this.router.navigate([
          this.utilsService.getProductBaseUrl(this.router, this.route),
          this.contextId,
          DEFINITION_TXT,
        ]);
      }
}
