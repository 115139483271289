import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ManageTrackedService } from './manage-tracked.service';
import { ActivatedRoute, Router } from '@angular/router';
import { auditTime, pairwise, startWith } from 'rxjs';
import { UtilsService } from '../utils/utilservice';
import { sortBy } from 'lodash-es';

@Component({
  selector: 'lib-manage-tracked',
  templateUrl: './manage-tracked.component.html',
  styleUrl: './manage-tracked.component.sass'
})
export class ManageTrackedComponent implements OnInit {
  formGroup: UntypedFormGroup;
  allTrackedAttribute = [];
  filteredTrackedAttribute = [];
  showLoader = false;
  contextId: string;
  scrollSortOption: string;
  scrollSortDirection: 'ASC' | 'DESC' = 'DESC';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private manageTrackedService: ManageTrackedService,
    private route: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService
  ) {
    this.formGroup = this.formBuilder.group({
      search: ''
    });
  }

  ngOnInit(): void {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.lookForSearchChange();
    this.getTrackedAttributes();
  }

  lookForSearchChange() {
    this.formGroup
      .get('search')
      .valueChanges.pipe(auditTime(250), startWith(''), pairwise())
      .subscribe(([prevSearchText, searchText]) => {
        if (prevSearchText === searchText) {
          return;
        }
        if (!searchText) {
          this.filteredTrackedAttribute = this.allTrackedAttribute;
          this.resetSorting();
          return;
        }
        this.filteredTrackedAttribute = this.allTrackedAttribute.filter((d) => {
          if (d.templateName && searchText) {
            return d.templateName
              .toLowerCase()
              .includes(searchText.toLowerCase());
          }
          return false;
        });
        this.resetSorting();
      });
  }

  getTrackedAttributes(filters = {}) {
    const body = {
      cabContextId: this.contextId,
      ...filters
    };
    this.allTrackedAttribute = [];
    this.showLoader = true;
    this.manageTrackedService.getTrackedAttributes(body).subscribe({
      next: (res: any) => {
        this.showLoader = false;
        if (res?.results?.length) {
          this.allTrackedAttribute = res.results;
          this.filteredTrackedAttribute = res.results;
        } else {
          this.allTrackedAttribute = [];
          this.filteredTrackedAttribute = [];
        }
        this.resetSorting();
      },
      error: (err) => {
        console.log(err);
        this.showLoader = false;
      }
    });
  }

  resetSorting() {
    this.scrollSortDirection = 'ASC';
    this.scrollSortOption = 'lastModifiedDate';
    this.sort('lastModifiedDate');
  }

  sort(sortOption: string) {
    if (this.scrollSortOption === sortOption) {
      this.scrollSortDirection =
        this.scrollSortDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.scrollSortDirection = 'ASC';
      this.scrollSortOption = sortOption;
    }
    if (
      sortOption === 'templateName' ||
      sortOption === 'lastModifiedDate' ||
      sortOption === 'lastModifiedBy'
    ) {
      this.filteredTrackedAttribute = sortBy(this.filteredTrackedAttribute, [
        function (o) {
          return o[sortOption];
        }
      ]);
    } else if (sortOption === 'trackedAttributesCount') {
      this.filteredTrackedAttribute = sortBy(this.filteredTrackedAttribute, [
        function (o) {
          return o.trackedAttributes?.length || 0;
        }
      ]);
    }
    if (this.scrollSortDirection === 'DESC') {
      this.filteredTrackedAttribute = this.filteredTrackedAttribute.reverse();
    }
  }

  createTrackedAttributes() {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      'tracked-attributes'
    ]);
  }

  editTrackedAttribute(trackedAttribute) {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      'tracked-attributes',
      trackedAttribute.id
    ]);
  }
}
