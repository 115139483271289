<div class="container">
  <div class="header-content">
    <h2>Tracked Attribute Sets</h2>
    <button (click)="createTrackedAttributes()" class="button-right" coreUIButton withIcon="left">
      Create Attribute Sets
    </button>
  </div>
  <div class="description">
    Create attribute sets from your profile or prospect data to track during
    owned deliveries, helping you gain insights for smarter, data-driven
    decisions.
  </div>
  <form class="search-form" [formGroup]="formGroup">
    <coreui-table-search formControlName="search" placeholder="Search"></coreui-table-search>
  </form>
  <coreui-table class="scroll-table" [fixedHeader]="true">
    <thead coreUITableSort>
      <tr coreUITableHeaderRow>
        <th scope="col" class="table-header not-sortable-header">
          <coreui-table-header-column (sort)="sort('templateName')" [active]="scrollSortOption === 'templateName'"
            [sortDirection]="scrollSortDirection">
            Attribute Set Name
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header not-sortable-header">
          Data Source Type
        </th>
        <th scope="col" class="table-header not-sortable-header">
          <coreui-table-header-column (sort)="sort('trackedAttributesCount')"
            [active]="scrollSortOption === 'trackedAttributesCount'" [sortDirection]="scrollSortDirection">
            Attribute Count
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header not-sortable-header">
          <coreui-table-multiple-header-column label="Last Updated">
            <coreui-table-header-column (sort)="sort('lastModifiedDate')"
              [active]="scrollSortOption === 'lastModifiedDate'" [sortDirection]="scrollSortDirection">
              Last Updated Date
            </coreui-table-header-column>
            <coreui-table-header-column (sort)="sort('lastModifiedBy')" [active]="scrollSortOption === 'lastModifiedBy'"
              [sortDirection]="scrollSortDirection">
              Last Updated By
            </coreui-table-header-column>
          </coreui-table-multiple-header-column>
        </th>
        <th scope="col" coreUITableActionColumn>
        </th>
      </tr>
    </thead>
    <tbody *ngIf="filteredTrackedAttribute.length">
      <tr *ngFor="let trackedAttribute of filteredTrackedAttribute" coreUITableRow>
        <td>
          <a (click)="editTrackedAttribute(trackedAttribute)" aria-label="List" class="text-style-link-2">
            {{ trackedAttribute.templateName }}
          </a>
        </td>
        <td>
          <div>{{ trackedAttribute.audienceType }}</div>
        </td>
        <td>
          <div>{{ trackedAttribute.trackedAttributes?.length || 0 }}</div>
        </td>
        <td>
          <div>
            <div class="has-bottom-space">
              {{
              trackedAttribute.lastModifiedDate
              | date: "MM/dd/YY hh:mm a":"UTC"
              }}
              UTC
            </div>
            <div class="text-style-muted-3">
              {{ "By " + trackedAttribute.lastModifiedBy }}
            </div>
          </div>
        </td>
        <td aria-label="actions" coreUITableActionColumn>
          <coreui-menu menuButtonStyle="link" icon="more">
            <coreui-menu-section>
              <button type="button" coreUIMenuItem (click)="editTrackedAttribute(trackedAttribute)">
                Open
              </button>
            </coreui-menu-section>
          </coreui-menu>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="showLoader">
      <tr coreUITableRow>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
      </tr>
    </tbody>
    <tbody *ngIf="!filteredTrackedAttribute.length && !showLoader">
      <tr coreUITableRow [disableHover]="true">
        <td colspan="5" class="empty-list">
          <coreui-graphic-message label="No Tracked Attribute Sets" graphic="empty">
            <div class="no-data-message">
              It looks like you haven't created any tracked attribute sets yet.
              To get started, click the "Create Attribute Set" button to build
              your first set.
            </div>
          </coreui-graphic-message>
        </td>
      </tr>
    </tbody>
  </coreui-table>
</div>