import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { first, get as _get, omit, reject, values, zipWith } from 'lodash-es';
import moment from 'moment';
import { startWith, auditTime, filter, map, take, pairwise } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import { ModalComponent, TableHeaderColumnComponent } from '@epsilon/core-ui';
import { DestroyAudience, DestroyAudiences, ResetAudiences, SearchAudiences } from '../../../audience/audience.actions';
import { AppState } from '../../../reducers';
import {
  selectAudiences,
  selectHasMoreAudiences, selectInitialStateAudience
} from '../../../audience/audience.reducer';
import { selectContext } from '../../../context/context.reducer';
import { selectActiveDataUniverse, selectDataUniverses } from '../../../data-universe/data-universe.reducer';
import { selectUser } from '../../../user/user.reducer';
import { Audience, AudienceDefinition } from '../../../audience/audience.models';
import { CampaignExtractStatus } from '../../../campaign-extract/campaign-extract.model';
import { DataUniverse } from '../../../data-universe/data-universe.models';
import { User } from '../../../user/user.models';
import { AudienceBuilderService } from '../../../audience-builder/audience-builder.service';
import { TableFilterService } from '../table-filter.service';
import { LoadingService } from '../../../services/loading.service';
import { FEATURE_EXPORT_CAMPAIGN_EXTRACTS } from '../../../utils/feature-utils';
import { fetchOutcome } from '../../../utils/fetch-state';
import {
  AUDIENCE_TXT,
  BE_STATUS_COMPLETE,
  BE_STATUS_ERROR,
  BE_STATUS_PROCESSING,
  BE_STATUS_SUCCESS,
  isDefined,
  showToastNotification,
  UI_STATUS_COMPLETE,
  UI_STATUS_ERROR
} from '../../../utils/utils';
import { CabConstants } from '../../../cab.constants';
import { FeatureService } from '../../../utils/feature-service';
import { UtilsService } from '../../../utils/utilservice';
import { FeatureAccessService } from '../../../feature-access/feature.access.service';
import { Action } from '../../../feature-access/feature.access.action';

@UntilDestroy()
@Component({
  selector: 'lib-audiences-table',
  templateUrl: './audiences-table.component.html',
  styleUrls: ['./audiences-table.component.sass']
})
export class AudiencesTableComponent implements OnInit, OnDestroy {
  @ViewChild('deleteActionModal') public deleteActionModal;
  @ViewChild('deleteActionOneModal') public deleteActionOneModal;
  @ViewChild('exportSuccess', { static: true }) public exportSuccess;
  @ViewChild('exportError', { static: true }) public exportError;
  @ViewChild('destroySuccess', { static: true }) public destroySuccess;
  @ViewChild('destroyError', { static: true }) public destroyError;

  actionInProgress$ = new BehaviorSubject<{
    type: string;
    audiences: Audience[];
  } | null>(null);
  userDetails: User;
  sorted$ = new Subject<void>();
  formGroup: UntypedFormGroup;
  filteredAudience: Audience[] = [];
  dropdownListActions = [];
  scrollSortOption = 'lastModifiedDate';
  scrollSortDirection = 'DESC';
  dataUniverses: DataUniverse[];
  contextId: string;
  productType: string;
  hasMore = false;
  refreshCount = 1;
  searchPayload: {
    dataUniverseId?: string;
    displayName?: string;
    limit?: number;
    offset?: number;
    sort?: string;
    sortBy?: string[];
    statuses?: string[];
    fromDateTime?: string;
    toDateTime?: string;
  } = { limit: 25, offset: 0, sortBy: [this.scrollSortOption], sort: this.scrollSortDirection };
  dedupeIdentityType: string;
  isContextExportDisabled: boolean;
  destroyErrorMessage: string;
  destroyErrorHeader: string;
  nestedDefs = '';
  successMessage: string;
  isLoader = false;
  initialLoad = true;
  isCampaignExtractEnabled: boolean;
  extractFromListEnabled: boolean;
  enableADHListSendTo: boolean;
  hasAccess = false;
  isPaidchannelTypeFeatureEnabled = true;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    protected tableFilterService: TableFilterService,
    private builderService: AudienceBuilderService,
    private actions$: Actions,
    protected loadingService: LoadingService,
    private featureService: FeatureService,
    private utilsService: UtilsService,
    private featureAccessService: FeatureAccessService,
    @Inject('FEATURE_ENABLED') private featureFlagService: any,
  ) {
    this.filteredAudience = null;
    this.formGroup = tableFilterService.formGroup;

    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.extractFromListEnabled = context?.metadata['extract.from.list.enabled'];
        this.productType = context?.productType;
        if (context && !context.exportAudience) {
          this.isContextExportDisabled = true;
        }
        if(context?.productType === CabConstants.DCDP_PRODUCT_NAME) {
          this.dropdownListActions = this.dropdownListActions.filter((item) => item.display === 'Delete');
        }
        if(this.productType) {
          this.isCampaignExtractEnabled = this.featureService.isFeatureEnabled(FEATURE_EXPORT_CAMPAIGN_EXTRACTS);
        }
        this.enableADHListSendTo = context?.enableADHListSendTo;
      });

    this.store
      .select(selectDataUniverses)
      .pipe(untilDestroyed(this))
      .subscribe((dataUniverses) => (this.dataUniverses = dataUniverses));

    this.store
      .select(selectUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => (this.userDetails = user));
  }

  ngOnInit() {
    this.filteredAudience = [];
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    const sessionState = this.tableFilterService.getSessionState();
    this.getPaidChannelFlag();
    this.scrollSortOption = _get(
      sessionState,
      ['audience', 'scrollSortOption'],
      this.scrollSortOption
    );
    this.scrollSortDirection = _get(
      sessionState,
      ['audience', 'scrollSortDirection'],
      this.scrollSortDirection
    );

    this.store.select(selectHasMoreAudiences).pipe(untilDestroyed(this)).subscribe(value => {
      this.hasMore = value;
    });
    this.store.select(selectInitialStateAudience).pipe(untilDestroyed(this)).subscribe((initialLoad: boolean) => {
      this.initialLoad = initialLoad;
    });

    this.isLoader = true;
    this.store.select(selectAudiences).pipe(map(values), untilDestroyed(this)).subscribe((audiences: Audience[])  => {
      this.filteredAudience = this.filteredAudience || [];
      // eslint-disable-next-line prefer-spread
      this.filteredAudience.push.apply(this.filteredAudience, audiences);
      this.tableFilterService.resetFormRows(this.filteredAudience);
      this.formGroup.get('headerCheckbox').setValue(this.tableFilterService.checkedAllStatus);
      if(this.filteredAudience.length || audiences.length === 0){
        this.isLoader = false;
      }
    });

    this.tableFilterService.statuses$.pipe(untilDestroyed(this)).subscribe((audienceListStatusArray: CampaignExtractStatus[]) => {
      let initiateSearch = false;
      if (this.searchPayload.statuses) {
        initiateSearch = true;
      }
      this.resetSearchPayload();
      const audienceListStatusBE = [];
      audienceListStatusArray.forEach(status => {
        if (status === UI_STATUS_COMPLETE) {
          audienceListStatusBE.push(BE_STATUS_COMPLETE);
          audienceListStatusBE.push(BE_STATUS_SUCCESS);
        } else if (status === UI_STATUS_ERROR) {
          audienceListStatusBE.push(BE_STATUS_ERROR);
        } else {
          audienceListStatusBE.push(BE_STATUS_PROCESSING);
        }
      });
      this.searchPayload.statuses = audienceListStatusBE;
      if (!this.initialLoad && initiateSearch && !this.isLoader) {
        this.isLoading();
      }
    });

    this.store.select(selectActiveDataUniverse).pipe(filter(isDefined)).pipe(untilDestroyed(this)).subscribe((dataUniverse: DataUniverse) => {
     this.resetSearchPayload();
      this.searchPayload.dataUniverseId = dataUniverse.id === 'all' ? '' : dataUniverse.id;
      if (!this.isLoader)
      {
        this.isLoading();
      }
    });

    /*const ownerChangeSubscription = this.formGroup.get('owner').valueChanges.pipe(startWith(false)).subscribe(res => {
      //ToDo Update API PAyload to filter for onlyMine
      this.resetSearchPayload();
      this.search();
    });*/

    this.formGroup.get('search').valueChanges.pipe(
      auditTime(250),
      startWith(''), pairwise(), untilDestroyed(this)).subscribe(([prevSearchText,searchText]) => {
        if(prevSearchText === searchText){
          return;
        }
        this.resetSearchPayload();
        this.searchPayload.displayName = searchText;
        if (!this.initialLoad) {
          this.isLoading();
        }
    });

    this.tableFilterService.dateTypes$.pipe(untilDestroyed(this)).subscribe((dateType: any) => {
      const dateObj = {
        today : 0,
        yesterday: 1,
        recent3Days : 2,
        recent7Days : 6,
        recent30Days : 29
      };
      let initiateSearch = false;
      if (dateType?.length > 0 && dateObj.hasOwnProperty(dateType)) {
        const filterDate: number = dateObj[dateType];
        const format = 'YYYY-MM-DDTHH:mm:ss';
        this.searchPayload.fromDateTime = moment.utc(moment().subtract(filterDate, 'days').hours(0).minutes(0).seconds(0).milliseconds(0)).format(format);
        if (dateType === 'yesterday'){
          this.searchPayload.toDateTime = moment.utc(moment().subtract(filterDate, 'days').hours(23).minutes(59).seconds(59).milliseconds(999)).format(format);
        } else {
          this.searchPayload.toDateTime = moment.utc(moment().hours(23).minutes(59).seconds(59).milliseconds(999)).format(format);
        }
        initiateSearch = true;
      } else {
        if (this.searchPayload.fromDateTime || this.searchPayload.toDateTime) initiateSearch = true;

        delete this.searchPayload.fromDateTime;
        delete this.searchPayload.toDateTime;
      }

      if (initiateSearch && this.tableFilterService.tableType$.getValue() === AUDIENCE_TXT) {
        this.resetSearchPayload();
        if (!this.initialLoad && !this.isLoader) {
          this.isLoading();
        }
      }
    });

    this.tableFilterService.tableType$.subscribe((value) => {
      if (value !== AUDIENCE_TXT) {
        this.searchPayload.dataUniverseId = undefined;
      }
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetAudiences());
    this.filteredAudience = [];
    this.tableFilterService.setSessionState(
      'audience',
      this.scrollSortOption,
      this.scrollSortDirection
    );
  }

  public getDataUniverseDisplayName(dataUniverseId: string) {
    const dataUniverse = this.dataUniverses.find(
      (dataUniverse) => dataUniverse.id === dataUniverseId
    );
    return dataUniverse?.displayName;
  }

  public getPaidChannelFlag() {
    this.utilsService.isPaidChanelFeatureEnabled().pipe(untilDestroyed(this))
      .subscribe((isPaidChannelTypeEnabled) => {
        this.isPaidchannelTypeFeatureEnabled = isPaidChannelTypeEnabled;
      });
  }

  public getDedupeTypeDisplayName(
    dataUniverseId: string,
    dedupeIdentityType: string
  ) {
    const dataUniverse = this.dataUniverses.find(
      (dataUniverse) => dataUniverse.id === dataUniverseId
    );
    return dataUniverse?.dedupeTypes.find(
      (dedupeType) => dedupeType.identityType === dedupeIdentityType
    )?.name;
  }
  public getAudienceDisplayName(dedupeType: string, channelType: string): string {
    return this.builderService.getAudienceDisplayName(dedupeType, channelType);
  }
  public scrollSort(sortComponent: TableHeaderColumnComponent, sortOption) {
    this.resetSearchPayload();
    this.scrollSortOption = sortOption;
    this.scrollSortDirection = sortComponent.sortDirection;
    this.searchPayload.sortBy = [sortOption];
    this.searchPayload.sort = this.scrollSortDirection;
    this.isLoading();
  }

  public sortAscDesc(option: string | string[]): string | undefined {
    return this.scrollSortOption === option || option.includes(this.scrollSortOption)
        ? this.scrollSortDirection === 'ASC'
            ? 'ascending'
            : 'descending'
        : undefined;
}

  // action from trash can icon
  public deleteActionMultiple() {
    if(this.tableFilterService.checkedAllStatus === true && this.tableFilterService.headerCheckboxIndeterminate=== false )
    {
      this.tableFilterService.rowSelected = 25 * this.refreshCount;
    }
    const selections = zipWith(
      this.filteredAudience,
      this.tableFilterService.rowsControl.controls,
      (definition, row) => {
        return { ...definition, selected: row.value };
      }
    );
    const audiences = selections
      .filter((selection) => selection.selected)
      .map((audience) => omit(audience, 'selected') as Audience);

    this.actionInProgress$.next({ type: 'multiDelete', audiences });
    this.deleteActionModal.show();
  }

  public deleteAction(modal: ModalComponent) {
    const actionDetails = this.actionInProgress$.getValue();
    if (actionDetails?.type === 'delete') {
      this.store.dispatch(new DestroyAudience(first(actionDetails.audiences)));
      this.readDestroyAudienceResponse();
    } else if (actionDetails?.type === 'multiDelete') {
      this.store.dispatch(new DestroyAudiences(actionDetails.audiences));
      this.actions$
        .pipe(fetchOutcome(DestroyAudiences.type), take(1))
        .subscribe(
          () => {
            this.destroySuccess.show();
            this.resetSearchPayload();
            this.isLoading();
          },
          (error) => {
            const initDataLength = actionDetails.audiences.length;
            const errorMessages = error ? JSON.parse(error.message) : [];
            const successLength = initDataLength - errorMessages.length;
            if (successLength > 0) {
              this.successMessage = successLength + ' Lists are deleted successfully!';
              this.destroySuccess.show();
            }
            if (successLength < initDataLength) {
              this.destroyErrorHeader = 'Unable to delete the following objects';
              this.destroyErrorMessage = errorMessages.toString() + ' can’t be deleted because they’re nested in other definitions and lists.';
              this.nestedDefs = '';
              this.destroyError.show();
            }
            this.resetSearchPayload();
            this.isLoading();
          }
        );
    }
    this.closeModal(modal);
    this.resetSearchPayload();
  }

  readDestroyAudienceResponse() {
    this.actions$
    .pipe(fetchOutcome(DestroyAudience.type), take(1))
    .subscribe(
      () => {
        this.destroyErrorMessage = 'This list has been deleted.';
        this.destroyError.show();
        this.resetSearchPayload();
        this.isLoading();
      },
      ({error}) => {
        const nestedDefs: Array<string> = JSON.parse(error).errorDetails ? JSON.parse(error).errorDetails[0].errorMessage.split('is referenced in ')[1].split(',') : [];
        this.destroyErrorHeader = 'Unable to delete this Object';
        if (nestedDefs.length > 1) {
          this.destroyErrorMessage = 'This object can’t be deleted because they’re nested in the following ' + nestedDefs.length + '  definitions or lists.';
        } else {
          this.destroyErrorMessage = 'This object can’t be deleted because it\'s nested in the following definition.';
        }
        this.nestedDefs = JSON.parse(error).errorDetails[0].errorMessage.split('is referenced in ')[1];
        this.destroyError.show();
        this.resetSearchPayload();
        this.isLoading();
      }
    );
  }

  public checkedCounter(event)
  {
    if(event.target.checked === true){
      this.tableFilterService.rowSelected=this.tableFilterService.rowSelected+1;
    }
    else if(event.target.checked === false)
    {
     this.tableFilterService.rowSelected=this.tableFilterService.rowSelected-1;
   }
  }

  public closeModal(modal: ModalComponent) {
    modal.hide();
    this.actionInProgress$.next(null);
  }

  public closeExportModal(exportStatus?: 'success' | 'error') {
    if (exportStatus) {
      const notification =
        exportStatus === 'success' ? this.exportSuccess : this.exportError;
      showToastNotification(notification);
    }
    this.resetActionInProgress();
  }

  public resetActionInProgress() {
    this.actionInProgress$.next(null);
  }

  public incompleteExport(action, audience): boolean {
    return (
      !action.disabled &&
      action.display === 'Export' &&
      audience.status !== UI_STATUS_COMPLETE
    );
  }


  public isFailedStatus(action, audience): boolean {
    return (
      !action.disabled &&
      action.display === 'Deliver To...' &&
      audience.status === UI_STATUS_ERROR
    );
  }

  public enabledDropdownListActions(item?) {
    this.addDropDownActionsList(item);
    return reject(this.dropdownListActions, { disabled: true });
  }

  public search() {
    this.hasAccess = this.featureAccessService.hasAccess(Action.AUDIENCE_VIEW);
    if(this.hasAccess) {
      if (this.tableFilterService.tableType$.getValue() === AUDIENCE_TXT && this.searchPayload.dataUniverseId !== undefined) {
        this.store.dispatch(new SearchAudiences(this.contextId, this.searchPayload));
      } else {
        this.isLoader = false;
        this.tableFilterService.rowSelected = 0;
      }
    } else {
        this.isLoader = false;
        this.filteredAudience = [];
        this.initialLoad = false;
    }
  }

  public limitReached(){
    if(this.hasMore){
      this.searchPayload.offset +=  25;
      this.refreshCount += 1;
      this.search();
    }
  }

  resetSearchPayload() {
    this.filteredAudience = [];
    this.searchPayload.offset = 0;
  }

  routeToViewDetails(item) {
    this.searchPayload.dataUniverseId = undefined;
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      item.dataUniverseId,
      AUDIENCE_TXT,
      'view',
      item.id,
    ]);
  }

  getDetailUrl(audience) {
    return `${this.utilsService.getProductBaseUrl(this.router, this.route)}/${this.contextId}/${audience.dataUniverseId}/${AUDIENCE_TXT}/view/${audience.id}`;
  }

  private addDropDownActionsList(item?: AudienceDefinition) {
    if (this.featureAccessService.hasAccess(Action.AUDIENCE_VIEW)) {
      this.dropdownListActions = [{
      display: 'View Details',
      onClick: (audience) => {
        this.searchPayload.dataUniverseId = undefined;
        this.router.navigate([
          this.utilsService.getProductBaseUrl(this.router, this.route),
          this.contextId,
          audience.dataUniverseId,
          AUDIENCE_TXT,
          'view',
          audience.id,
        ]);
      }
    },
      {
        display: 'Export',
        disabled: (!this.isCampaignExtractEnabled) || this.isContextExportDisabled,
        onClick: (audience) => {
          if (audience.status === UI_STATUS_COMPLETE) {
            this.actionInProgress$.next({
              type: 'export',
              audiences: [audience],
            });
          }
        },
      }];
    }

    if (this.featureAccessService.hasAccess(Action.AUDIENCE_PAID_RUN) && this.productType === CabConstants.DCDP_PRODUCT_NAME && item?.audienceAttributes?.channelType === 'PAID') {
      this.dropdownListActions.push({
        display: 'Deliver To...',
        onClick: (list) => {
          if (list.status === UI_STATUS_COMPLETE) {
              this.sendToAMS(list);
          }
        },
        disabled: !this.extractFromListEnabled || (this.productType !== CabConstants.DCDP_PRODUCT_NAME)
      });
    }

    if (this.featureAccessService.hasAccess(Action.AUDIENCE_OWNED_RUN) && this.productType === CabConstants.DCDP_PRODUCT_NAME && item?.audienceAttributes?.channelType === 'OWNED') {
      this.dropdownListActions.push({
        display: 'Deliver To...',
        onClick: (list) => {
          if (list.status === UI_STATUS_COMPLETE) {
              if(this.enableADHListSendTo) {
                this.router.navigate([
                  this.utilsService.getProductBaseUrl(this.router, this.route),
                  this.contextId,
                  list.dataUniverseId,
                  list.id,
                  'sendTo'
                ], {queryParams: {cabId: list.cabId, type: 'list'}});
              } else {
                this.router.navigate([
                  this.utilsService.getProductBaseUrl(this.router, this.route),
                  this.contextId,
                  list.dataUniverseId,
                  list.id,
                  'cab',
                  'sendTo'
                ], {queryParams: {cabId: list.cabId, type: 'list'}});
            }
          }
        },
        disabled: !this.extractFromListEnabled || (this.productType !== CabConstants.DCDP_PRODUCT_NAME)
      });
    }

    if (this.featureAccessService.hasAccess(Action.AUDIENCE_DELETE)) {
      this.dropdownListActions.push({
        display: 'Delete',
        onClick: (audience) => {
          this.actionInProgress$.next({ type: 'delete', audiences: [audience] });
          this.deleteActionOneModal.show();
        },
      });
    }
  }

  sendToAMS(list) {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      list.dataUniverseId,
      list.id,
      'ams'
    ], {queryParams: {audienceId: list.audienceAttributes.amsAudienceId, type: 'list'}});
  }

  public isLoading(){
    this.hasAccess = this.featureAccessService.hasAccess(Action.AUDIENCE_VIEW);
    if (this.hasAccess) {
      this.isLoader = true;
      this.search();
      if (this.filteredAudience.length) {
        this.isLoader = false;
      }
    } else {
      this.isLoader = false;
      this.filteredAudience = [];
      this.initialLoad = false;
    }
  }
}