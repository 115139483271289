<div [formGroup]="formGroup" class="page-scaffolding">
  <coreui-table
    class="scroll-table"
    #scrollTable
    coreUIInfiniteScroll
    [scrollTarget]="scrollTable.scrollContainer"
    (scrollLimitReached)="limitReached()"
    [fixedHeader]="true"
  >
    <thead coreUITableSort>
      <tr coreUITableHeaderRow>
        <th
          scope="col"
          class="table-header"
          [attr.aria-sort]="sortAscDesc('name')"
        >
          <coreui-table-header-column
            (sort)="scrollSort('name')"
            [active]="scrollSortOption === 'name'"
            [sortDirection]="scrollSortDirection"
          >
            Definition
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header">
          <coreui-table-header-column [disableSort]="true">
            Channel
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header">
          <coreui-table-header-column [disableSort]="true">
            Audience
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header">
          <coreui-table-header-column
            [active]="scrollSortOption === 'count.count'"
            [disableSort]="true"
          >
            Count
          </coreui-table-header-column>
        </th>
        <th
          scope="col"
          class="table-header"
          [attr.aria-sort]="sortAscDesc(['data.lastModifiedDate', 'data.lastModifiedBy'])"
          [attr.aria-describedby]="
            scrollSortOption === 'data.lastModifiedDate'
              ? 'UpdatedDate-Sort'
              : scrollSortOption === 'data.lastModifiedBy'
              ? 'UpdatedBy-Sort'
              : undefined
          "
        >
          <coreui-table-multiple-header-column label="Updated">
            <coreui-table-header-column
              (sort)="scrollSort('data.lastModifiedDate')"
              [active]="scrollSortOption === 'data.lastModifiedDate'"
              [sortDirection]="scrollSortDirection"
            >
              Update Date
            </coreui-table-header-column>
            <coreui-table-header-column
              (sort)="scrollSort('data.lastModifiedBy')"
              [active]="scrollSortOption === 'data.lastModifiedBy'"
              [sortDirection]="scrollSortDirection"
            >
              Updated By
            </coreui-table-header-column>
          </coreui-table-multiple-header-column>
        </th>
        <th scope="col" coreUITableActionColumn>
        </th>
      </tr>
    </thead>

    <tbody
      coreUICheckboxMultipleSelection
      (multipleSelected)="tableFilterService.onMultipleSelected($event)"
      formArrayName="rows"
    >
      <tr
        *ngFor="
          let item of filteredDefinitions
            | orderBy: scrollSortOption:scrollSortDirection;
          index as i
        "
        coreUITableRow
        [active]="tableFilterService.rowsControl?.at(i)?.value"
      >
      <ng-container *ngIf="isPaidchannelTypeFeatureEnabled ? true : item.audienceAttributes?.channelType !== 'PAID'">
        <td class="nameCol">
          <span *ngIf="productType !== 'DCDP'">{{ item.name }}</span>
          <a
            class="text-style-link-2"
            aria-label="Definition List"
            [routerLink]="
              absoluteUrl +
              item?.dataUniverseId +
              '/' +
              'builder' +
              '/' +
              'edit' +
              '/' +
              item?.id
            "
            (click)="routeToEditHandler(item)"
            *ngIf="productType === 'DCDP'"
            >{{ item.name }}</a
          >
          <div class="tag-column">
            <coreui-tag [disabled]="true" [removable]="false" *ngFor="let tag of item.tags">{{tag.tag}}</coreui-tag>
          </div>
            <i class="definitionIconClass" *ngIf="item.audienceAttributes?.genAIConversationId" coreUIIcon="wand"></i>
          <span>
            <i
              *ngIf="item?.audienceAttributes?.isPVEAudience"
              coreUIIcon="promo"
              scale="1.5"
              [coreUITooltip]="optimizedTooltip" position="right"
            ></i>
            <i
              *ngIf="!item?.audienceAttributes?.isPVEAudience"
              coreUIIcon="audiences"
              scale="1.5"
              [coreUITooltip]="sharedTooltip" position="right"
            ></i>
            <ng-template #optimizedTooltip>
              <span class="tooltipMessage">
                Optimized Audience
              </span>
            </ng-template>
            <ng-template #sharedTooltip>
              <span class="tooltipMessage">
                Shared Audience
              </span>
            </ng-template>
          </span>
        </td>
        <td>
          <span> {{ item?.audienceAttributes?.channelType | titlecase }}</span>
        </td>
        <td>
          {{
            getAudienceDisplayName(
              item?.dedupeIdentityType,
              productType !== "DCDP"
                ? ""
                : item?.audienceAttributes?.channelType,
              item?.audienceAttributes?.alternateKeyType
            )
          }}
        </td>
        <td *ngIf="!item?.count?.count && !item?.count?.calculatedAt">
          <span class="text-style-muted-3">—</span>
        </td>
        <td *ngIf="item?.count?.count || item?.count?.calculatedAt">
          <div *ngIf="item?.count?.status === 'SUCCESS'">
            <div class="has-bottom-space">
              {{ item?.count?.count | number }}
            </div>
            <div class="text-style-muted-3">
              as of
              {{ item?.count?.calculatedAt | date: "MM/dd/YY hh:mm a":"UTC" }}
              UTC
            </div>
          </div>
          <div *ngIf="item?.count?.status === 'FAILURE'">
            <ng-template #errorMessage>
              <span class="countErrorMessage">
                {{ item?.count?.countErrorMessage }}
              </span>
            </ng-template>
            <coreui-badge
              color="Apple"
              [filled]="true"
              [hasIcon]="true"
            >
              <i coreUIIcon="errorShield"> </i>Error
            </coreui-badge>
            <div class="text-style-muted-3">
              on
              {{ item?.count?.calculatedAt | date: "MM/dd/YY hh:mm a":"UTC" }}
              UTC
            </div>
          </div>
          <div *ngIf="!item?.count?.status">
            <coreui-badge color="Slate" [filled]="true" [hasIcon]="true">
              <i coreUIIcon="spinner"></i>Processing
            </coreui-badge>
          </div>
        </td>
        <td>
          <div>
            <div class="has-bottom-space">
              {{ item?.lastModifiedDate | date: "MM/dd/YY hh:mm a":"UTC" }}
              UTC
            </div>
            <div class="text-style-muted-3">
              {{ "by " + item?.lastModifiedBy }}
            </div>
          </div>
        </td>
        <td aria-label="actions" coreUITableActionColumn>
          <coreui-menu menuButtonStyle="link" icon="more" *ngIf="item?.actions?.length">
            <coreui-menu-section>
              @for (action of item.actions; track action.display) {
                <button
                  type="button"
                  coreUIMenuItem
                  (click)="action.onClick(item)"
                >
                  {{ action.display }}
                </button>
              }
            </coreui-menu-section>
          </coreui-menu>
        </td>
      </ng-container>
      </tr>
      <tr
        coreUITableRow
        *ngIf="
          (loadingService.isLoading$ | async) &&
          !filteredDefinitions.length
        "
      >
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
      </tr>
      <tr
        *ngIf="
          hasAccess &&
          ((loadingService.isLoading$ | async) === null ||
            (loadingService.isLoading$ | async) === undefined ||
            (loadingService.isLoading$ | async) === false) &&
          !initialLoad &&
          !filteredDefinitions.length
        "
        coreUITableRow
        [disableHover]="true"
      >
        <td colspan="9" class="empty-list">
          <coreui-graphic-message label="No Results Found" graphic="noResults">
            Sorry, we did not find any results for your search.
          </coreui-graphic-message>
        </td>
      </tr>
      <tr
        *ngIf="
          !filteredDefinitions.length &&
          !loadingService.isLoading$
        "
        coreUITableRow
        [disableHover]="true"
      >
        <td colspan="9" class="empty-list">
          <coreui-graphic-message label="Nothing Here" graphic="empty">
            There are no saved definitions.
          </coreui-graphic-message>
        </td>
      </tr>
    </tbody>
  </coreui-table>
</div>

<coreui-modal modalWidth="40" #deleteActionOneModal>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon"></span>
    <span> Delete Audience Definition? </span>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeModal(deleteActionOneModal)"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <span class="text-style-muted-3 template-text"
      >{{ (actionInProgress$ | async)?.definitions[0].displayName }} | ID -
      {{ (actionInProgress$ | async)?.definitions[0].id }}
    </span>
    <span>
      Are you sure you want to delete this audience definition? This cannot be
      undone.
    </span>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal(deleteActionOneModal)">
      Cancel
    </button>
    <button coreUIButton (click)="deleteAction(deleteActionOneModal)">
      Delete
    </button>
  </ng-template>
</coreui-modal>

<lib-create-list *ngIf="showCreateList" (createListModalClosed)="closeCreateListModal()"></lib-create-list>

<coreui-notification notificationStyle="" #destroySuccess>
  {{ successMessage }}
</coreui-notification>

<coreui-notification
  notificationStyle="error"
  #destroyError
  [header]="destroyErrorHeader"
>
  {{ destroyErrorMessage }}
  <p *ngIf="nestedDefs.length > 0">"{{ nestedDefs }}"</p>
</coreui-notification>


<coreui-notification notificationStyle="success" #successCreatingList>
  <b>List created successfully</b>
  <br />
</coreui-notification>
