import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { map, tap, startWith, auditTime, filter, take, pairwise } from 'rxjs/operators';
import { first, get as _get, omit, reject, values, zipWith } from 'lodash-es';
import moment from 'moment';

import { TableHeaderColumnComponent, ModalComponent } from '@epsilon/core-ui';

import { AppState } from '../../../reducers';
import {
  selectAudienceDefinitions,
  selectHasMoreAudienceDefinitions,
  selectInitialStateAudienceDefinitions
} from '../../../audience/audience.reducer';
import { selectContext } from '../../../context/context.reducer';
import { selectActiveDataUniverse, selectDataUniverses } from '../../../data-universe/data-universe.reducer';
import { selectTenantContext } from '../../../tenant/tenant.reducer';
import { selectUser } from '../../../user/user.reducer';
import {
  DestroyAudienceDefinition,
  DestroyAudienceDefinitions,
  LoadAudienceForCreateList,
  ResetAudienceDefinitions,
  SearchAudienceDefinition
} from '../../../audience/audience.actions';
import { AudienceDefinition } from '../../../audience/audience.models';
import { DataUniverse } from '../../../data-universe/data-universe.models';
import { User } from '../../../user/user.models';
import { AudienceService } from '../../../audience/audience.service';
import { AudienceBuilderService } from '../../../audience-builder/audience-builder.service';
import { CampaignExtractService } from '../../../campaign-extract/campaign-extract.service';
import { LoadingService } from '../../../services/loading.service';
import { TableFilterService } from '../table-filter.service';
import { fetchOutcome } from '../../../utils/fetch-state';
import {
  FEATURE_AUDIENCES,
  FEATURE_EXPORT_CAMPAIGN_EXTRACTS,
  FEATURE_SCHEDULING,
} from '../../../utils/feature-utils';
import { BUILDER_TXT, DEFINITION_TXT, isDefined, showToastNotification } from '../../../utils/utils';
import { CabConstants } from '../../../cab.constants';
import { FeatureService } from '../../../utils/feature-service';
import { UtilsService } from '../../../utils/utilservice';
import { getAudienceDedupeType } from '../../../audience/audience.utils';
import { FeatureAccessService } from '../../../feature-access/feature.access.service';
import { Action } from '../../../feature-access/feature.access.action';

@UntilDestroy()
@Component({
  selector: 'lib-definitions-table',
  templateUrl: './definitions-table.component.html',
  styleUrls: ['./definitions-table.component.sass'],
})
export class DefinitionsTableComponent implements OnInit, OnDestroy {
  @ViewChild('deleteActionModal') public deleteActionModal;
  @ViewChild('deleteActionOneModal') public deleteActionOneModal;
  @ViewChild('exportSuccess', { static: true }) public exportSuccess;
  @ViewChild('exportError', { static: true }) public exportError;
  @ViewChild('destroySuccess', { static: true }) public destroySuccess;
  @ViewChild('destroyError', { static: true }) public destroyError;
  @ViewChild('successCreatingList', { static: true }) public successCreatingList;

  actionInProgress$ = new BehaviorSubject<{
    type: string;
    definitions: AudienceDefinition[];
  } | null>(null);
  userDetails: User;

  sorted$ = new Subject<void>();
  formGroup: UntypedFormGroup;
  schedulingEnabled: boolean;
  listsEnabled: boolean;
  featureExportCampaignExtract: boolean;
  isProductTypeDcdp: boolean;
  isProductTypeProspect: boolean;
  dropdownListActions = [];
  filteredDefinitions$: Observable<AudienceDefinition[]>;
  filteredDefinitions: AudienceDefinition[] = [];
  scrollSortOption = 'lastModifiedDate';
  scrollSortDirection: 'ASC' | 'DESC' = 'DESC';
  dataUniverses: DataUniverse[];
  contextId: string;
  hasMore = true;
  destroyErrorMessage: string;
  destroyErrorHeader: string;
  nestedDefs = '';
  successMessage: string;
  productType: string;
  absoluteUrl:string;
  refreshCount = 1;
  showCreateList = false;
  initialLoad = true;
  filterDropdownListActionsArray = [];

  paginationPayload: {
    dataUniverseId?: string;
    displayName?: string;
    limit?: number;
    offset?: number;
    sort?: string;
    sortBy?: string[];
    statuses?: string[];
    fromDateTime?: string;
    toDateTime?: string;
  } = { limit: 25, offset: 0, sortBy: [this.scrollSortOption], sort: this.scrollSortDirection };
  private isContextExportDisabled: boolean;
  isPaidchannelTypeFeatureEnabled = true;
  definitionWriteAction = Action.AUDIENCE_DEFINITION_WRITE;
  hasAccess = false;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private campaignExtractService: CampaignExtractService,
    protected tableFilterService: TableFilterService,
    private actions$: Actions,
    private audienceService: AudienceService,
    private builderService: AudienceBuilderService,
    protected loadingService: LoadingService,
    private featureService: FeatureService,
    private utilsService: UtilsService,
    @Inject('FEATURE_ENABLED') private featureFlagService: any,
    public featureAccessService: FeatureAccessService
  ) {
    this.formGroup = tableFilterService.formGroup;

    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        if (context && !context.exportAudience) {
          this.isContextExportDisabled = true;
        }
        this.productType = context?.productType;
        if(context?.productType === CabConstants.DCDP_PRODUCT_NAME) {
          this.isProductTypeDcdp = true;
        }
        if(context?.productType === CabConstants.PROSPECT_PRODUCT_NAME) {
          this.isProductTypeProspect = true;
        }
        if(this.productType) {
          this.schedulingEnabled = this.featureService.isFeatureEnabled(FEATURE_SCHEDULING);
          this.listsEnabled = this.featureService.isFeatureEnabled(FEATURE_AUDIENCES);
          this.featureExportCampaignExtract = this.featureService.isFeatureEnabled(FEATURE_EXPORT_CAMPAIGN_EXTRACTS);
        }
        this.addActionsToTable();
        this.enabledDropdownListActions();
      });

    this.store
      .select(selectDataUniverses)
      .pipe(untilDestroyed(this))
      .subscribe((dataUniverses) => (this.dataUniverses = dataUniverses));

    this.store
      .select(selectUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => (this.userDetails = user));
  }

  ngOnInit() {

    this.contextId = this.route.snapshot.paramMap.get('contextId');

    this.absoluteUrl = '/' + this.utilsService.getProductBaseUrl(this.router, this.route) + '/' + this.contextId + '/';
  
    const sessionState = this.tableFilterService.getSessionState();
    this.getPaidChannelFlag();
    this.store.select(selectTenantContext).pipe(untilDestroyed(this)).subscribe(contextId => {
      if(contextId && contextId !== this.contextId) {
        this.contextId = contextId;
      }
    });
    this.scrollSortOption = _get(
      sessionState,
      ['definition', 'scrollSortOption'],
      this.scrollSortOption
    );
    this.scrollSortDirection = _get(
      sessionState,
      ['definition', 'scrollSortDirection'],
      this.scrollSortDirection
    );

    this.store.select(selectHasMoreAudienceDefinitions).pipe(untilDestroyed(this)).subscribe((value) => {
      this.hasMore = value;
    });

    this.store.select(selectAudienceDefinitions).pipe(map(values), untilDestroyed(this)).subscribe((audienceDefinitions: AudienceDefinition[]) => {
      this.initialLoad = false;
      this.filteredDefinitions.push(...audienceDefinitions);
      this.enabledDropdownListActions();
      this.tableFilterService.resetFormRows(this.filteredDefinitions);
    });
    this.store.select(selectInitialStateAudienceDefinitions).pipe(untilDestroyed(this)).subscribe((initialLoad: boolean) => {
      this.initialLoad = initialLoad;
    });

    this.store.select(selectActiveDataUniverse).pipe(filter(isDefined), untilDestroyed(this)).subscribe((dataUniverse: DataUniverse) => {
      this.resetpaginationPayload();
      this.paginationPayload.dataUniverseId = dataUniverse.id === 'all' ? '' : dataUniverse.id;
      this.search();
    });

    /*this.formGroup.get('owner').valueChanges.pipe(startWith(false)).subscribe(res => {
      //ToDo Update API PAyload to filter for onlyMine
      this.resetpaginationPayload();
      this.search();
    });*/

    this.formGroup.get('search').valueChanges.pipe(
      auditTime(250),
      startWith(''),
      pairwise(),
      untilDestroyed(this)
    ).subscribe(([prevSearchTerm, searchTerm]) => {
      if(prevSearchTerm === searchTerm){
        return;
      }
      this.resetpaginationPayload();
      this.paginationPayload.displayName = searchTerm?.toLowerCase();
      if (!this.initialLoad) {
        this.search();
      }
    });

    this.tableFilterService.dateTypes$.pipe(untilDestroyed(this)).subscribe((dateType: any) => {
      const dateObj = {
        today : 0,
        yesterday: 1,
        recent3Days : 2,
        recent7Days : 6,
        recent30Days : 29
      };
      if (dateType?.length > 0 && dateObj.hasOwnProperty(dateType)) {
        const filterDate: number = dateObj[dateType];
        const format = 'YYYY-MM-DDTHH:mm:ss';
        this.paginationPayload.fromDateTime = moment.utc(moment().subtract(filterDate, 'days').hours(0).minutes(0).seconds(0).milliseconds(0)).format(format);
        if (dateType === 'yesterday'){
          this.paginationPayload.toDateTime = moment.utc(moment().subtract(filterDate, 'days').hours(23).minutes(59).seconds(59).milliseconds(999)).format(format);
        }else{
          this.paginationPayload.toDateTime = moment.utc(moment().hours(23).minutes(59).seconds(59).milliseconds(999)).format(format);
        }
      } else {
        delete this.paginationPayload.fromDateTime;
        delete this.paginationPayload.toDateTime;
      }
      this.resetpaginationPayload();
      if (!this.initialLoad) {
        this.search();
      }
    });
    this.search();

  }

  public routeToEditHandler(definition){
    const isPVEAudience = definition?.audienceAttributes?.isPVEAudience;
    const displayMode = (isPVEAudience) ? 'view': (this.featureAccessService.hasAccess(Action.AUDIENCE_DEFINITION_WRITE)) ?'edit' : 'view';
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      definition.dataUniverseId,
      'builder',
      displayMode,
      definition.id
    ]);
  }
  ngOnDestroy() {
    this.store.dispatch(new ResetAudienceDefinitions());
    this.filteredDefinitions = [];
    this.tableFilterService.setSessionState(
      'definition',
      this.scrollSortOption,
      this.scrollSortDirection
    );
  }

  public getDataUniverseDisplayName(dataUniverseId: string): string {
    const dataUniverse = this.dataUniverses.find(
      (dataUniverse) => dataUniverse.id === dataUniverseId
    );
    return dataUniverse?.displayName;
  }

    public getDedupeTypeChannelName(dataUniverseId: string)  {
    const dataUniverse = this.dataUniverses.find(
      (dataUniverse) => dataUniverse.id === dataUniverseId
    );
    return dataUniverse?.dedupeTypes;
  }

  public getAudienceDisplayName(dedupeType: string, channelType: string, alternateKeyType:string): string {
    return this.builderService.getAudienceDisplayName(dedupeType, channelType, alternateKeyType);
  }

  public getPaidChannelFlag() {
    this.utilsService.isPaidChanelFeatureEnabled().pipe(untilDestroyed(this))
      .subscribe((isPaidChannelTypeEnabled) => {
        this.isPaidchannelTypeFeatureEnabled = isPaidChannelTypeEnabled;
      });
  }

  public getDedupeTypeDisplayName(
    dataUniverseId: string,
    dedupeIdentityType: string
  ): string {
    const dataUniverse = this.dataUniverses.find(
      (dataUniverse) => dataUniverse.id === dataUniverseId
    );
    return dataUniverse?.dedupeTypes.find(
      (dedupeType) => dedupeType.identityType === dedupeIdentityType
    )?.name;
  }

  public scrollSort(sortComponent: TableHeaderColumnComponent, sortOption) {
    this.scrollSortOption = sortOption;
    this.scrollSortDirection = sortComponent.sortDirection;
    this.paginationPayload.sort = this.scrollSortDirection;
    this.paginationPayload.sortBy = [this.scrollSortOption];
    this.resetpaginationPayload();
    this.search();
  }

  public sortAscDesc(option: string | string[]): string | undefined {
    return this.scrollSortOption === option || option.includes(this.scrollSortOption)
        ? this.scrollSortDirection === 'ASC'
            ? 'ascending'
            : 'descending'
        : undefined;
}

  // action from trash can icon
  public deleteActionMultiple() {
    if(this.tableFilterService.checkedAllStatus === true && this.tableFilterService.headerCheckboxIndeterminate === false )
    {
      this.tableFilterService.rowSelected = 25 * this.refreshCount;
    }
    const selections = zipWith(
      this.filteredDefinitions,
      this.tableFilterService.rowsControl.controls,
      (definition, row) => {
        return { ...definition, selected: row.value };
      }
    );
    const definitions = selections
      .filter((selection) => selection.selected)
      .map((definition) => omit(definition, 'selected') as AudienceDefinition);

    this.actionInProgress$.next({ type: 'multiDelete', definitions });
    this.deleteActionModal.show();
  }

  public deleteAction(modal: ModalComponent) {
    const actionDetails = this.actionInProgress$.getValue();
    if (actionDetails?.type === 'delete') {
      const definition = first(actionDetails.definitions);
      this.store.dispatch(new DestroyAudienceDefinition(definition));
      this.actions$
        .pipe(fetchOutcome(DestroyAudienceDefinition.type), take(1))
        .subscribe(
          () => {
            this.successMessage = 'This definition has been deleted.';
            this.destroySuccess.show();
            this.resetpaginationPayload();
            this.search();
          },
          ({error}) => {
            const nestedDefs: Array<string> = JSON.parse(error).errorDetails ? JSON.parse(error).errorDetails[0].errorMessage.split('is referenced in ')[1].split(',') : [];
            this.destroyErrorHeader = 'Unable to delete this Object';
            if (nestedDefs.length > 1) {
              this.destroyErrorMessage = 'This object can’t be deleted because they’re nested in the following ' + nestedDefs.length + '  definitions or lists.';
            } else {
              this.destroyErrorMessage = 'This object can’t be deleted because it\'s nested in the following definition.';
            }
            this.nestedDefs = JSON.parse(error).errorDetails[0].errorMessage.split('is referenced in ')[1];
            this.destroyError.show();
            this.resetpaginationPayload();
            this.search();
          }
        );
    } else if (actionDetails?.type === 'multiDelete') {
      this.audienceService.errorMessages = [];
      this.audienceService.successFullyDeletedIds = [];
      this.store.dispatch(
        new DestroyAudienceDefinitions(actionDetails.definitions)
      );
      this.actions$
        .pipe(fetchOutcome(DestroyAudienceDefinitions.type), take(1))
        .subscribe(
          () => {
            this.destroySuccess.show();
            this.resetpaginationPayload();
            this.search();
          },
          (error) => {
            const initDataLength = actionDetails.definitions.length;
            const errorMessages = error ? JSON.parse(error.message) : [];
            const successLength = initDataLength - errorMessages.length;
            if (successLength > 0) {
              this.successMessage = successLength + ' definitions are deleted successfully!';
              this.destroySuccess.show();
              this.audienceService.successFullyDeletedIds.forEach(value => {
                const index = this.filteredDefinitions.findIndex(aud => aud.id === value);
                this.filteredDefinitions.splice(index, 1);
                this.filteredDefinitions$ = of(this.filteredDefinitions);
                this.filteredDefinitions$
                  .pipe(
                    tap(this.tableFilterService.resetFormRows.bind(this)),
                    untilDestroyed(this)
                  )
                  .subscribe((definitions) => {
                    this.filteredDefinitions = definitions;
                    this.enabledDropdownListActions();
                  });
              });
            }
            if (successLength < initDataLength) {
              this.destroyErrorHeader = 'Unable to delete the following objects';
              this.destroyErrorMessage = errorMessages.toString() + ' can’t be deleted because they’re nested in other definitions and lists.';
              this.nestedDefs = '';
              this.destroyError.show();
            }
            this.resetpaginationPayload();
            this.search();
          }
        );
    }
    this.closeModal(modal);
  }

  public closeModal(modal: ModalComponent) {
    modal.hide();
    this.resetActionInProgress();
  }

  public closeExportModal(exportStatus?: 'success' | 'error') {
    if (exportStatus) {
      const notification =
        exportStatus === 'success' ? this.exportSuccess : this.exportError;
      showToastNotification(notification);
    }
    this.resetActionInProgress();
  }

  public closeCreateListModal(createListStatus?: 'success' | 'error') {
    if (createListStatus === 'success') {
      showToastNotification(this.successCreatingList);
    }
    this.showCreateList = false;
  }

  public resetActionInProgress() {
    this.actionInProgress$.next(null);
  }

  public enabledDropdownListActions(): void {
    if(this.isProductTypeDcdp) {
      this.addActionsToTable();
      this.dropdownListActions = this.dropdownListActions.filter((item) => item.display === 'Delete' || item.display === 'Create Audience List' || item.display === 'Deliver To...');
    }
    if(this.isProductTypeProspect) {
      this.addActionsToTable();
      this.dropdownListActions = this.dropdownListActions.filter((item) => item.display === 'Delete');
    }
    this.filterDropdownListActionsArray = reject(this.dropdownListActions, { disabled: true });
  }

  public getActionsForDefinition(definition: AudienceDefinition) {
    if (this.isProductTypeDcdp) {
      this.addActionsToTable(definition);
      const getDataUniverseId = definition.dataUniverseId;
      const getDedupeTypeListSupported = getAudienceDedupeType(
        getDataUniverseId,
        definition.dedupeIdentityType,
        this.dataUniverses
      );
      if (getDedupeTypeListSupported?.listSupported) {
        this.dropdownListActions = this.dropdownListActions.filter(
          (item) =>
            item.display === 'Delete' ||
            item.display === 'Create Audience List' ||
            item.display === 'Deliver To...'
        );
      } else {
        this.dropdownListActions = this.dropdownListActions.filter(
          (item) =>
            item.display === 'Delete' || item.display === 'Deliver To...'
        );
      }
    }
    this.filterDropdownListActionsArray = reject(this.dropdownListActions, {
      disabled: true,
    });
    return this.filterDropdownListActionsArray;
  }

  public limitReached(){
    if(this.hasMore){
      this.paginationPayload.offset +=  25;
      this.refreshCount+=1;
      this.search();
    }
  }

  public search() {
    this.hasAccess = this.featureAccessService.hasAccess(Action.DEFINITION_VIEW);
    if(this.hasAccess && ('dataUniverseId' in this.paginationPayload)){
      this.tableFilterService.rowSelected=0;
      this.store.dispatch(new SearchAudienceDefinition(this.contextId, this.paginationPayload));
    }
  }

  resetpaginationPayload(){
    this.paginationPayload.limit = 25;
    this.paginationPayload.offset = 0;
    this.filteredDefinitions = [];
  }

  sendToAMS(definition) {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      definition.dataUniverseId,
      definition.id,
      'ams'
    ], {queryParams: {audienceId: definition.audienceAttributes.amsAudienceId}});
  }

 public checkedCounter(event)
 {
   if(event.target.checked === true){
     this.tableFilterService.rowSelected=this.tableFilterService.rowSelected+1;
   }
   else if(event.target.checked === false)
   {
    this.tableFilterService.rowSelected=this.tableFilterService.rowSelected-1;
  }
 }


  private addActionsToTable(definition?: AudienceDefinition) {
    this.dropdownListActions = [{
        display: 'Edit',
        onClick: (definition) => {
          this.router.navigate([
            this.utilsService.getProductBaseUrl(this.router, this.route),
            this.contextId,
            definition.dataUniverseId,
            BUILDER_TXT,
            'edit',
            definition.id,
          ]);
        },
        disabled: false
      },
      {
        display: 'Export',
        disabled: !this.featureExportCampaignExtract && this.isContextExportDisabled,
        onClick: (definition) => {
          this.actionInProgress$.next({
            type: 'export',
            definitions: [definition],
          });
        },
      },
     ];

    if (this.featureAccessService.hasAccess(Action.AUDIENCE_VIEW)) {
      this.dropdownListActions.push({
        display: "View Details",
        onClick: (definition) => {
          this.router.navigate([
            this.utilsService.getProductBaseUrl(this.router, this.route),
            this.contextId,
            definition.dataUniverseId,
            DEFINITION_TXT,
            "view",
            definition.id,
          ]);
        },
        disabled: false,
      });
    }
    
    if (this.featureAccessService.hasAccess(Action.AUDIENCE_DEFINITION_WRITE)) {
      this.dropdownListActions.push({
        display: "Create Audience List",
        onClick: (definition) => {
          this.showCreateList = true;
          this.store.dispatch(new LoadAudienceForCreateList(definition));
        },
        disabled: !this.listsEnabled,
      });
    }
    
    if (this.featureAccessService.hasAccess(Action.DEFINITION_PAID_RUN) && this.isProductTypeDcdp && definition?.audienceAttributes?.channelType === 'PAID') {
      this.dropdownListActions.push({
        display: "Deliver To...",
        onClick: (definition) => {
          this.sendToAMS(definition);
        },
        disabled: !this.isProductTypeDcdp,
      });
    }
    if (this.featureAccessService.hasAccess(Action.DEFINITION_OWNED_RUN) && this.isProductTypeDcdp && definition?.audienceAttributes?.channelType === 'OWNED') {
      this.dropdownListActions.push({
        display: "Deliver To...",
        onClick: (definition) => {
          this.router.navigate(
              [
                this.utilsService.getProductBaseUrl(this.router, this.route),
                this.contextId,
                definition.dataUniverseId,
                definition.id,
                "sendTo",
              ],
              { queryParams: { cabId: definition.cabId, type: "defintion" } }
            );
        },
        disabled: !this.isProductTypeDcdp,
      });
    }
    if (this.featureAccessService.hasAccess(Action.DEFINITION_DELETE)) {
      this.dropdownListActions.push({
        display: "Delete",
        onClick: (definition) => {
          this.actionInProgress$.next({
            type: "delete",
            definitions: [definition],
          });
          this.deleteActionOneModal.show();
        },
        disabled: false,
      });
    }
  }
}