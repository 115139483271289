<div [formGroup]="formGroup" class="page-scaffolding">
  <coreui-table
    class="scroll-table"
    #scrollTable
    coreUIInfiniteScroll
    [scrollTarget]="scrollTable.scrollContainer"
    (scrollLimitReached)="limitReached()"
    [fixedHeader]="true"
  >
    <thead coreUITableSort>
      <tr coreUITableHeaderRow>
        <th
          scope="col"
          class="table-header"
          [attr.aria-sort]="sortAscDesc('name')"
        >
          <coreui-table-header-column
            (sort)="scrollSort('name')"
            [active]="scrollSortOption === 'name'"
            [sortDirection]="scrollSortDirection"
          >
            List
          </coreui-table-header-column>
        </th>
        <th scope="col" class="table-header">
          <coreui-table-header-column [disableSort]="true">
            Channel
        </coreui-table-header-column>
        <th scope="col" class="table-header">
          <coreui-table-header-column [disableSort]="true">
            Audience
        </coreui-table-header-column>
        <th scope="col" class="table-header">
          <coreui-table-header-column
            [active]="scrollSortOption === 'count'"
            [disableSort]="true"
          >
            Count
          </coreui-table-header-column>
        </th>
        <th
          scope="col"
          class="table-header"
          [attr.aria-sort]="sortAscDesc(['data.lastModifiedDate', 'data.lastModifiedBy'])"
          [attr.aria-describedby]="
            scrollSortOption === 'data.lastModifiedDate'
              ? 'UpdatedDate-Sort'
              : scrollSortOption === 'data.lastModifiedBy'
              ? 'UpdatedBy-Sort'
              : undefined
          "
        >
          <coreui-table-multiple-header-column label="Updated">
            <coreui-table-header-column
              (sort)="scrollSort('data.lastModifiedDate')"
              [active]="scrollSortOption === 'data.lastModifiedDate'"
              [sortDirection]="scrollSortDirection"
            >
              Updated Date
            </coreui-table-header-column>
            <coreui-table-header-column
              (sort)="scrollSort('data.lastModifiedBy')"
              [active]="scrollSortOption === 'data.lastModifiedBy'"
              [sortDirection]="scrollSortDirection"
            >
              Updated By
            </coreui-table-header-column>
          </coreui-table-multiple-header-column>
        </th>
        <th scope="col" class="table-header">
          <coreui-table-header-column [disableSort]="true">
            Status
        </coreui-table-header-column>
          </th>
        <th scope="col" class="table-header" coreUITableActionColumn>
        </th>
      </tr>
    </thead>
    <tbody coreUICheckboxMultipleSelection formArrayName="rows">
      <tr
        *ngFor="
          let item of filteredAudience
            | orderBy: scrollSortOption:scrollSortDirection;
          index as i
        "
        coreUITableRow
      >
      <ng-container *ngIf="isPaidchannelTypeFeatureEnabled ? true : item.audienceAttributes?.channelType !== 'PAID'">
        <td class="nameCol">
          <a
            aria-label="List"
            class="text-style-link-2"
            routerLink="{{ getDetailUrl(item) }}"
            (click)="routeToViewDetails(item)"
          >
            {{ item?.name }}
          </a>
          <div class="tag-column">
            <coreui-tag [disabled]="true" [removable]="false" *ngFor="let tag of item.tags">{{tag.tag}}</coreui-tag>
          </div>
        </td>
        <td>
          <div>
            {{ item?.audienceAttributes?.channelType | titlecase }}
          </div>
        </td>
        <td>
          <div>
            {{
              getAudienceDisplayName(
                item?.dedupeIdentityType,
                item?.audienceAttributes?.channelType
              )
            }}
          </div>
        </td>
        <td *ngIf="!item.countUpdatedOn"><span class="text-style-muted-3">—</span></td>
        <td *ngIf="item.countUpdatedOn">
          <div class="has-bottom-space">{{ item.idCount | number }}</div>
          <div class="text-style-muted-3"> as of {{ item.countUpdatedOn | coreUILocaleDate }} </div>
        </td>
        <td>
          <div>
            <div class="has-bottom-space">
              {{ item?.lastModifiedDate | date: "MM/dd/YY hh:mm a":"UTC" }}
              UTC
            </div>
            <div class="text-style-muted-3">
              {{ "by " + item?.lastModifiedBy }}
            </div>
          </div>
        </td>
        <td>
          <coreui-badge
            class="badgeWithTime"
            *ngIf="item?.status === 'Completed'"
            color="Lime"
            [hasIcon]="true"
            ><i coreUIIcon="successShield"></i>Completed</coreui-badge
          >
          <coreui-badge
            class="badgeWithTime"
            *ngIf="item?.status === 'Failed'"
            color="Apple"
            [hasIcon]="true"
            ><i coreUIIcon="errorShield"></i>Failed</coreui-badge
          >
          <coreui-badge
            class="badgeWithTime"
            *ngIf="item?.status === 'Processing'"
            color="Slate"
            [hasIcon]="true"
            ><i coreUIIcon="spinner"></i>Processing</coreui-badge
          >
        </td>
        <td coreUITableActionColumn>
          <coreui-menu menuButtonStyle="link" icon="more">
            <coreui-menu-section>
              <ng-template #exportTooltip
                >Cannot export an incomplete Audience List</ng-template
              >

              @for (action of enabledDropdownListActions(item); track action.display) {
                <button
                  type="button"
                  coreUIMenuItem
                  (click)="action.onClick(item)">
                  <span
                    *ngIf="incompleteExport(action, item)"
                    [coreUITooltip]="exportTooltip"
                  >{{ action.display }}</span
                  >
                  <span *ngIf="!incompleteExport(action, item)">{{
                      action.display
                    }}</span>
                </button>
              }
            </coreui-menu-section>
          </coreui-menu>
        </td>
        </ng-container>
      </tr>
      <tr coreUITableRow *ngIf="!filteredAudience.length && isLoader">
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
        <td *ngIf="enabledDropdownListActions(item)?.length">
          <coreui-loading-table-column></coreui-loading-table-column>
        </td>
        <td><coreui-loading-table-column></coreui-loading-table-column></td>
      </tr>
      <tr
        *ngIf="
          hasAccess &&
          ((loadingService.isLoading$ | async) === null ||
            (loadingService.isLoading$ | async) === undefined ||
            (loadingService.isLoading$ | async) === false) &&
          !initialLoad &&
          !filteredAudience.length
        "
        coreUITableRow
        [disableHover]="true"
      >
        <td colspan="9" class="empty-list">
          <coreui-graphic-message label="No Results Found" graphic="noResults">
            Sorry, we did not find any results for your search.
          </coreui-graphic-message>
        </td>
      </tr>
      <tr
        *ngIf="!filteredAudience.length && !loadingService.isLoading$"
        coreUITableRow
        [disableHover]="true"
      >
        <td colspan="9" class="empty-list">
          <coreui-graphic-message label="Nothing Here" graphic="empty">
            There are no saved lists.
          </coreui-graphic-message>
        </td>
      </tr>
    </tbody>
  </coreui-table>
</div>

<coreui-modal modalWidth="40" #deleteActionOneModal>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon"></span>
    <span> Delete Audience List? </span>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button
      type="button"
      (click)="closeModal(deleteActionOneModal)"
      coreUIButton="icon-large"
    >
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <span class="text-style-muted-3 template-text"
      >{{ (actionInProgress$ | async)?.audiences[0].displayName }} | ID -
      {{ (actionInProgress$ | async)?.audiences[0].id }}
    </span>
    <span>
      Are you sure you want to delete this audience list? This cannot be undone.
    </span>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal(deleteActionOneModal)">
      Cancel
    </button>
    <button coreUIButton (click)="deleteAction(deleteActionOneModal)">
      Delete
    </button>
  </ng-template>
</coreui-modal>

<coreui-notification notificationStyle="success" #destroySuccess>
  {{successMessage}}
</coreui-notification>

<coreui-notification notificationStyle="error" #destroyError [header]=destroyErrorHeader>
  {{destroyErrorMessage}}
  <p *ngIf="nestedDefs.length > 0"> "{{nestedDefs}}"</p>
</coreui-notification>

<coreui-notification notificationStyle="" #deleteSuccess>
  {{deleteSuccessMessage}}
</coreui-notification>
