<div class="header-container">
  <div class="header-link">
    <div *ngIf="productType === 'DCDP'" class="hasBreadcrumb">
      <div *ngIf="!isMonitor && audience">
        <div class="hasBreadcrumb">
          <coreui-breadcrumbs>
            <a [routerLink]="audiencesRouter" coreUIBreadcrumbLink>Lists</a>
            <span coreUIBreadcrumbSlash></span>
            <a href="javascript:void(0)" coreUIBreadcrumbEnd> {{formGroup.get('displayName').value}}</a>
          </coreui-breadcrumbs>
        </div>
        <h1>{{formGroup.get('displayName').value}}</h1>
        <coreui-standalone-accordion label="Details" [active]="false">
          <div class="standalone">
            <div class="standalone__content">
              <div class="label text-style-4">ID:</div>
              <div class="value text-style-5">{{ audienceId }}</div>
            </div>
            <div class="standalone__content">
              <div class="label text-style-4">Created:</div>
              <div class="value text-style-5">
                {{ audienceItem?.createdDate | date: 'MM/dd/YY hh:mm a': 'UTC' }} UTC
                <span class="header-subvalue"> by {{ audienceItem?.createdBy }}</span>
              </div>
            </div>
            <div class="standalone__content">
              <div class="label text-style-4">Updated:</div>
              <div class="value text-style-5">
                {{ audienceItem?.lastModifiedDate | date: 'MM/dd/YY hh:mm a': 'UTC' }} UTC
                <span class="header-subvalue"> by {{ audienceItem?.lastModifiedBy }}</span>
              </div>
            </div>
            <div class="standalone__content">
              <div class="label text-style-4">Status:</div>
              <div class="value">
                <coreui-badge
                  [color]="(audienceItem?.status === 'Error' || audienceItem?.status === 'Failed') ? 'Apple' : 'Lime'"
                  [hasIcon]="audienceItem?.status === 'Processing'">
                  <i coreUIIcon="spinner" *ngIf="audienceItem?.status === 'Processing'"></i>{{ audienceItem?.status }}
                </coreui-badge>
              </div>
            </div>
          </div>
        </coreui-standalone-accordion>
      </div>
      <coreui-breadcrumbs *ngIf=" isMonitor">
        <a [routerLink]="activityRouter" coreUIBreadcrumbLink>Deliveries</a>
        <span coreUIBreadcrumbSlash></span>
        <a href="javascript:void(0)" coreUIBreadcrumbEnd>Delivery Details</a>
      </coreui-breadcrumbs>
    </div>
  </div>
  <h1 *ngIf="!isMonitor && productType !== 'DCDP'">Audience {{ displayType | titlecase }} Details</h1>
  <div>
    <lib-monitor-overview [casAttributeId]="casAttributeId" [data]="campaignExtractItem" [monitor]="isMonitor"></lib-monitor-overview>
    <lib-audience-builder-details
      *ngIf="audience && !isMonitor && productType !== 'DCDP'"></lib-audience-builder-details>
  </div>
  <div class="header-body" *ngIf="!!audience && !isMonitor &&  productType !== 'DCDP'" [formGroup]="formGroup">
    <coreui-form-field label="" class="name-input">
      <input type="text" formControlName="displayName" coreUIInput coreUIInlineEdit [isPersistent]="true"
        [isHeader]="true" (approve)="onNameApprove()" (focusout)="onNameFocusout()" />
    </coreui-form-field>
    <coreui-combo-button buttonLabel="Actions" buttonType="primary" *ngIf="!isAudience()">
      <button type="button" coreUIMenuItem *ngIf="!isAudience()" (click)="editItem()">
        Edit
      </button>
      <button type="button" coreUIMenuItem *ngIf="!isAudience()" (click)="createListItem()">
        Create Audience List
      </button>
      <button type="button" coreUIMenuItem (click)="deleteItem()">
        Delete
      </button>
    </coreui-combo-button>
  </div>
</div>


<ng-container *ngIf="audience && activeDataUniverse && audienceItem">
  <coreui-tab-group aria-label="horizontal-tab-group" *ngIf="activationSchedulesEnabled && channelType === 'OWNED' && productType === 'DCDP'; else isPaidorNotDcdp">
    <coreui-tab label="List">
      <div class="tabs">
        <ng-template [ngTemplateOutlet]="definitionDetail"
                     *ngIf="!showSchedulingTab() && !(campaignExtractItem?.sendToTargetType === 'AMS')"></ng-template>
      </div>
    </coreui-tab>
    <coreui-tab label="Activation Schedules">
      <div class="tabs">
        <lib-audience-activation-schedules></lib-audience-activation-schedules>
      </div>
    </coreui-tab>
  </coreui-tab-group>
  <ng-template #isPaidorNotDcdp>
    <ng-template [ngTemplateOutlet]="definitionDetail"
                 *ngIf="!showSchedulingTab() && !(campaignExtractItem?.sendToTargetType === 'AMS')"></ng-template>
  </ng-template>
  <div class="actionGroup" *ngIf="isAudience()">
    <button coreUIButton="secondary" coreUIMenuItem *ngIf="!isAudience()" (click)="editItem()">
      Edit
    </button>
    <button coreUIButton="secondary" coreUIMenuItem *ngIf="!isAudience()" (click)="createListItem()">
      Create List
    </button>
    <button coreUIButton="secondary" coreUIMenuItem (click)="deleteItem()" *ngIf="featureAccessService.hasAccess(definitionDeleteAction)">
      Delete this List
    </button>
  </div>

  <div *ngIf="showSchedulingTab()">
    <coreui-tab-group tabStyle="secondary">
      <coreui-tab label="Definition" [active]="true">
        <div class="tabs">
          <lib-detail-body [dataTypeOperators]="dataTypeOperators$ | async" [dataUniverse]="activeDataUniverse"
            [audience]="audienceItem" [isAudience]="isAudience()" [dedupeDisplayName]="dedupeDisplayName"
            [attributeDetails]="attributeDetails" [audienceForm]="audienceForm"
            [activeDedupeType]="audienceForm?.get('dedupeType').value" [isEdit]="isEdit"
            (handleInvalidDefinitionError)="handleInvalidDefinitionError()" (submitUpdateCount)="submitUpdateCount()">
          </lib-detail-body>
        </div>
      </coreui-tab>
      <coreui-tab label="Schedules" *ngIf="productType !== 'DCDP'">
        <div class="tabs">
          <lib-schedules-overview [audienceItem]="audienceItem"></lib-schedules-overview>
        </div>
      </coreui-tab>
      <coreui-tab label="History" *ngIf="productType !== 'DCDP'" [disabled]="true"></coreui-tab>
    </coreui-tab-group>
  </div>

  <div *ngIf="campaignExtractItem?.sendToTargetType === 'AMS'">
    <coreui-tab-group aria-label="horizontal-tab-group" *ngIf="paidConnectionInfoEnabled; else definition">
      <coreui-tab label="Destination" [active]="true">
        <div class="tabs">
          <coreui-table class="tableScroll" #scrollTable coreUIInfiniteScroll
            [scrollTarget]="scrollTable.scrollContainer" [fixedHeader]="true">
            <thead>
              <tr coreUITableHeaderRow>
                <th scope="col">
                  <coreui-table-header-column [disableSort]="true">
                    Destination
                  </coreui-table-header-column>
                </th>
                <th scope="col">
                  <coreui-table-header-column [disableSort]="true">
                    Destination Status
                  </coreui-table-header-column>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="!isLoader">
              <tr coreUITableRow *ngFor="let connection of connections">
                <td>
                  <div class="connector">
                    <img alt="connector logo" class="connector-logo" [src]="fetchConnectorLogos(connection?.destination?.platform?.name)">
                    {{ connection?.destination?.platform?.name ? connection?.destination?.platform?.name : '—' }}
                  </div>
                </td>
                <!--
              <td>—</td>
              <td>—</td>
              -->
                <td>
                  <coreui-badge color="Apple" [subMessage]="updatedAt">
                    {{ connection?.displayState ? connection?.displayState : '—' }}
                  </coreui-badge>
                  <ng-template #updatedAt>on {{ connection?.updatedAt | date: 'MM/dd/yyyy' }}</ng-template>
                </td>
              </tr>
            </ng-container>
              <ng-container *ngIf="isLoader">
                <tr coreUITableRow>
                    <td><coreui-loading-table-column></coreui-loading-table-column></td>
                    <td><coreui-loading-table-column></coreui-loading-table-column></td>
                </tr>
            </ng-container>
            </tbody>
          </coreui-table>
        </div>
      </coreui-tab>
      <coreui-tab label="Audience Details">
        <div class="readOnlyDetails">
          <coreui-form-field label="Audience End Date">
            <input coreUIInput value = "{{ audienceEndDate ? (audienceEndDate | coreUILocaleDate)  : '—' }}" readonly/> 
          </coreui-form-field>
        </div>
      </coreui-tab>
      <coreui-tab label="Use Case">
        <div class="readOnlyDetails">
          <coreui-form-field label="What are you using this audience for?">
            <input coreUIInput value = "{{ connections && connections[0]?.useCase?.name ? connections[0]?.useCase?.name : '—' }}"  readonly/>
          </coreui-form-field>
          <coreui-form-field label="What kind of Media Targeting?">
            <input coreUIInput value="EPC Audience Activation"  readonly/>
            
          </coreui-form-field>
        </div>
      </coreui-tab>
      <coreui-tab [label]="campaignExtractItem?.audienceDefinitionId ? 'Definition' : 'List'">
        <div class="tabs">
          <ng-template [ngTemplateOutlet]="definitionDetail"></ng-template>
        </div>
      </coreui-tab>
    </coreui-tab-group>
    <ng-template #definition>
      <ng-template [ngTemplateOutlet]="definitionDetail"></ng-template>
    </ng-template>
  </div>

  <a href="javascript:void(0);" class="backToDef" *ngIf="isMonitor && productType === 'DCDP'"
    [routerLink]="activityRouter">
    Back to all Deliveries
  </a>

  <a href="javascript:void(0);" class="backToDef" *ngIf="productType !== 'DCDP' && isMonitor"
    [routerLink]="monitorRouter">
    Back to Monitor History
  </a>

  <a href="javascript:void(0);" *ngIf="!isMonitor && productType === 'DCDP'" class="backToDef"
    [routerLink]="audiencesRouter">
    Back to all lists
  </a>

  <a href="javascript:void(0);" *ngIf="!isMonitor && productType !== 'DCDP' && isAudience()" class="backToDef"
    [routerLink]="audiencesRouter">
    Back to Audience List
  </a>

  <a href="javascript:void(0);" *ngIf="!isMonitor && productType !== 'DCDP' && !isAudience()" class="backToDef"
    [routerLink]="audienceDefRouter">
    Back to Audience Definition
  </a>

</ng-container>

<ng-container *ngIf="!audience && (loadingService.isLoading$ | async) === false">
  <coreui-graphic-message graphic="noResults" label="No Results Found">
    Sorry, we did not find any results.
  </coreui-graphic-message>
</ng-container>

<coreui-modal modalWidth="40" #deleteActionModal>
  <ng-template #titleTemplate>
    <span coreUIIcon="warning" class="Modal-titleIcon"></span> Delete Audience {{ displayType | titlecase }}?
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" (click)="closeModal(deleteActionModal)" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <span class="text-style-muted-3 template-text">{{audienceItem?.displayName}} | ID -
      {{audienceItem?.id}}
    </span>
    <span>
      Are you sure you want to delete this audience {{ displayType }}? This cannot be undone.
    </span>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button coreUIButton="secondary" (click)="closeModal(deleteActionModal)">
      Cancel
    </button>
    <button coreUIButton (click)="deleteAction(deleteActionModal)">
      Delete
    </button>
  </ng-template>
</coreui-modal>

<lib-create-list *ngIf="showCreateList" (createListModalClosed)="closeCreateListModal()"></lib-create-list>

<coreui-notification notificationStyle="success" #successCreatingList>
  <b>List created successfully</b>
  <br />
</coreui-notification>

<coreui-notification notificationStyle="error" [isPersistent]="false" #errorNotification>
  {{ errorMessage }}
</coreui-notification>

<coreui-notification notificationStyle="" #destroyError>
  {{destroyErrorMessage}}
</coreui-notification>

<ng-template #definitionDetail>
  <lib-detail-body [dataTypeOperators]="dataTypeOperators$ | async" [dataUniverse]="activeDataUniverse"
    [audience]="audienceItem" [isAudience]="isAudience()" [dedupeDisplayName]="dedupeDisplayName"
    [attributeDetails]="attributeDetails" [showHeader]="!isMonitor">
  </lib-detail-body>
</ng-template>
