<form [formGroup]="formGroup">
  <div class="table-container">
    <div class="table-toggle-container" *ngIf="tableFilterService.tableType$.value === 'deliveries' && isPaidchannelTypeFeatureEnabled">
      <coreui-icon-toggle (valueChanged)="tableFilterService.onTargetTypeClick($event)">
        <button type="button" coreUIIconToggleButton *ngFor="let type of tableType" [active]="type.active"
          [toggleValue]="type.status">
          <span>{{type.name}}</span>
        </button>
      </coreui-icon-toggle>
      <ng-template #tooltip>
        <span class="tooltipMessage">
          Paid channels include <br />digital TV, CTV, etc.<br /><br />
          Owned channels include <br />email, direct mail, etc.
        </span>
      </ng-template>
      <div class="tooltip-container">
        <div class="tooltip-content">
          <a href="javascript: void(0)" [coreUITooltip]="tooltip" position="right">
            <i coreUIIcon="help" scale="1.4"></i>
          </a>
        </div>
      </div>
    </div>
  
    <ng-container *ngIf="!((tableType$ | async) === 'deliveries' && productType === 'DCDP' && isShowFeedTable)">
      <coreui-action-bar>
        <ng-template #leftTpl *ngIf="(tableType$ | async) !== 'monitor' && productType !== 'DCDP'">
          <div class="left-container">
            <div class="actionsWrapper">
              <coreui-checkbox-menu [checkboxTemplate]="checkboxTemplate">
                <ng-template #checkboxTemplate>
                  <label>
                    <input type="checkbox" coreUICheckbox [indeterminate]="tableFilterService.headerCheckboxIndeterminate"
                      formControlName="headerCheckbox" (change)="checkedAll($event)" />
                  </label>
                </ng-template>
              </coreui-checkbox-menu>
        
              <ng-container *ngIf="tableFilterService.hasSelectedRows &&  productType !== 'DCDP' ">
                <button type="button" (click)="deleteActionMultiple()" coreUIButton="icon-large" coreUITooltip="Delete">
                  <i coreUIIcon="trash"></i>
                </button>
              </ng-container>
            </div>
            <coreui-checkbox-group *ngIf="(tableType$ | async) !== 'monitor' && displayFiltersForDefinitionsAndList"
              id="owner-filter">
              <ng-template #showMineTooltip>
                Only show objects created and last updated by me
              </ng-template>
              <!--Current API Doesnt support that commenting out for future enhancement-->
              <!-- <coreui-form-field
                        label="Only show mine"
                        [coreUITooltip]="showMineTooltip"
                        position="bottom"
                      >
                        <input type="checkbox" coreUICheckbox formControlName="owner" />
                      </coreui-form-field> -->
            </coreui-checkbox-group>
          </div>
        </ng-template>

        <ng-template #centerTpl>
          <coreui-filter-group label="Start Date" class="costsGroup" [style.margin-left]="((tableType$ | async) !== 'monitor' && productType !== 'DCDP') ? '1.5rem' : 0 "
           style="margin-right: 10px;"
            *ngIf="(tableType$ | async) === 'monitor' && (monitorTableSubType$ | async) !== 'scheduled'">
            <coreui-filter [autoFocus]="true" data-qa="costsFilter-filters" panelCssClass="costsPanel" #dateFilter
              [buttonLabel]="dateTypeLabel">
              <coreui-menu-section label="Start Date">
                <ul role="radiogroup" class="toggle-list">
                  <li role="radio" class="toggle-listItem" tabindex="0" *ngFor="let dateType of dateTypes"
                    (click)="onDateFilterClick(dateType.value, dateType.name)"
                    (keyup.enter)="onDateFilterClick(dateType.value, dateType.name);">
                    {{dateType.name}}
                  </li>
                </ul>
              </coreui-menu-section>
            </coreui-filter>
          </coreui-filter-group>

          <lib-pcm-common-tag-filter *ngIf="(tableType$ | async) !== 'deliveries' && assetTypeFilterVisible && productType === 'DCDP'" [items]="selectedTags" (emitSelectedTags)="getSelectedTags($event)" [assetType]="assetTypeForFilter"></lib-pcm-common-tag-filter>
          
          <coreui-filter-group
            label="{{productType === 'DCDP' && (tableType$ | async) === 'deliveries' ? 'Started' : 'Updated'}}"
            *ngIf="(tableType$ | async) !== 'monitor' && displayFiltersForDefinitionsAndList" [style.margin-left]="((tableType$ | async) !== 'monitor' && productType !== 'DCDP') ? '1.5rem' : 0 ">
            <coreui-filter  #updatedDateFilter [buttonLabel]="dateTypeLabel">
              <coreui-filter-select label="{{productType === 'DCDP' && (tableType$ | async) === 'deliveries' ? 'Started' : 'Updated'}}">
                <div class="toggle-list" [class.scroll]="dateTypes.length > 8">
                  <button type="button" [active]="dateType.name === dateTypeLabel" *ngFor="let dateType of dateTypes"
                    coreUIFilterOption (click)="onUpdatedDateFilterClick(dateType.value, dateType.name)"
                    (keyup.enter)="onUpdatedDateFilterClick(dateType.value, dateType.name);">
                    {{dateType.name}}
                  </button>
                </div>
              </coreui-filter-select>
            </coreui-filter>
          </coreui-filter-group>

          <coreui-filter-group label="Job Type"
            *ngIf="(tableType$ | async) === 'monitor' && (monitorTableSubType$ | async) === 'history'" [style.margin-left]="((tableType$ | async) !== 'monitor' && productType !== 'DCDP') ? '1.5rem' : 0 ">
            <coreui-filter #jobTypeFilter [autoFocus]="true" [buttonLabel]="tableFilterService.jobTypeButtonLabel$ | async"
              [autoHide]="false">
              <coreui-filter-select label="Job Type">
                <coreui-checkbox-group orientation="vertical" formArrayName="jobTypes" [class.scroll]="jobTypes.length > 8">
                  <coreui-form-field *ngFor="let jobType of jobTypes; index as i" [label]="jobType.name">
                    <input type="checkbox" [attr.aria-label]="jobType" coreUICheckbox [formControlName]="i" />
                  </coreui-form-field>
                </coreui-checkbox-group>
              </coreui-filter-select>
              <coreui-menu-item-divider></coreui-menu-item-divider>
              <div coreUIButtonGroup class="panel-buttons">
                <button type="button" coreUIButton="text-primary" (click)="onJobTypeClearClick()">
                  Clear
                </button>
                <button type="button" coreUIButton="text-primary" (click)="onJobTypeApplyClick()">
                  Apply
                </button>
              </div>
            </coreui-filter>
          </coreui-filter-group>

          <coreui-filter-group label="Job Type"
            *ngIf="(tableType$ | async) === 'monitor' && (monitorTableSubType$ | async) === 'scheduled'" [style.margin-left]="((tableType$ | async) !== 'monitor' && productType !== 'DCDP') ? '1.5rem' : 0 ">
            <coreui-filter #scheduleTypeFilter [autoFocus]="true" [buttonLabel]="tableFilterService.scheduleTypeButtonLabel$ | async"
              [autoHide]="false">
              <coreui-filter-select label="Job Type">
                <coreui-checkbox-group orientation="vertical" formArrayName="scheduleTypes" [class.scroll]="scheduleTypes.length > 8">
                  <coreui-form-field *ngFor="let scheduleType of scheduleTypes; index as i" [label]="scheduleType.name">
                    <input type="checkbox" [attr.aria-label]="scheduleType" coreUICheckbox [formControlName]="i" />
                  </coreui-form-field>
                </coreui-checkbox-group>
              </coreui-filter-select>
              <coreui-menu-item-divider></coreui-menu-item-divider>
              <div coreUIButtonGroup class="panel-buttons">
                <button type="button" coreUIButton="text-primary" (click)="onScheduleTypeClearClick()">
                  Clear
                </button>
                <button type="button" coreUIButton="text-primary" (click)="onScheduleTypeApplyClick()">
                  Apply
                </button>
              </div>
            </coreui-filter>
          </coreui-filter-group>

          <coreui-filter-group label="Status"
            *ngIf="((tableType$ | async) !== 'definition' && (monitorTableSubType$ | async) !== 'scheduled') || (tableType$ | async) === 'audience'"
            [style.margin-left]="((tableType$ | async) !== 'monitor' && productType !== 'DCDP') ? '1.5rem' : 0 ">
            <coreui-filter #statusFilter [autoFocus]="true" [buttonLabel]="tableFilterService.statusButtonLabel$ | async" [autoHide]="false">
              <coreui-filter-select label="Status">
                <coreui-checkbox-group orientation="vertical" formArrayName="statuses" [class.scroll]="statuses.length > 8">
                  <coreui-form-field *ngFor="let status of statuses; index as i" [label]="status" [value]="statuses[i]">
                    <input type="checkbox" [attr.aria-label]="status" coreUICheckbox [formControlName]="i" />
                  </coreui-form-field>
                </coreui-checkbox-group>
              </coreui-filter-select>
              <coreui-menu-item-divider></coreui-menu-item-divider>
              <div coreUIButtonGroup class="panel-buttons">
                <button type="button" coreUIButton="text-primary" (click)="onStatusClearClick()">
                  Clear
                </button>
                <button type="button" coreUIButton="text-primary" (click)="onStatusApplyClick()">
                  Apply
                </button>
              </div>
            </coreui-filter>
          </coreui-filter-group>
          

          <coreui-filter-group label="Audience" *ngIf="((tableType$ | async) === 'audience' || (tableType$ | async) === 'definition') && productType === 'DCDP'" >
            <coreui-filter #audienceFilter [autoFocus]="true" [buttonLabel]="tableFilterService.audienceDisplayNameTypesButtonLabel$ | async"
              [autoHide]="false">
              <coreui-filter-select label="Audience">
                <coreui-checkbox-group orientation="vertical" formArrayName="audienceDisplayNameTypes"
                  [class.scroll]="audienceDisplayNameTypes.length > 8">
                  <coreui-form-field *ngFor="let audienceDisplayNameType of audienceDisplayNameTypes; index as i" [label]="audienceDisplayNameType.name" [value]="audienceDisplayNameType.value">
                    <input type="checkbox" [attr.aria-label]="audienceDisplayNameType.name" coreUICheckbox [formControlName]="i" />
                  </coreui-form-field>
                </coreui-checkbox-group>
              </coreui-filter-select>
              <coreui-menu-item-divider></coreui-menu-item-divider>
              <div coreUIButtonGroup class="panel-buttons">
                <button type="button" coreUIButton="text-primary" (click)="onAudienceDisplayNameTypeClearClick()">
                  Clear
                </button>
                <button type="button" coreUIButton="text-primary" (click)="onAudienceDisplayNameTypeApplyClick()">
                  Apply
                </button>
              </div>
            </coreui-filter>
          </coreui-filter-group>

        </ng-template>
        <ng-template #rightTpl>
          <coreui-table-search formControlName="search" placeholder="Search"></coreui-table-search>
          <div class="view-filter" *ngIf="(tableType$ | async) !== 'deliveries' && saveSearchEnabled && productType === 'DCDP'">
            <lib-pcm-common-saved-view-filter #saveViewFilterComponent 
              (searchSavedFilter)="onSavedViewApplyClick($event)"
              [searchId]="searchId"
              [assetType]="assetTypeForFilter" />
          </div>
        </ng-template>
      </coreui-action-bar>
    </ng-container>
  </div>
</form>