
export class AudienceAIChatRequest {

  chat_history: ChatMessage[] = [];
  input_message: string;
  data_universe_id: string;
  business_unit_id: string | null;

}

export class Conversation {
  id: string;
  user_session_id: string;
  user_id: string;
  tenant_code: string;
  product_type: string;
  start_time: number;
  modified_time: number;
  end_time: number | null;
  data_universe_id: string;
  conversation_state: ConversationState;
  chat_history: BaseMessage[] = [];
}

export class AudienceAIChatResponse {

  llm_output: string;
  chat_message: string;
  options: string[] = [];
  conversation_state: ConversationState
  response_type: AudienceAIResponseType;
  response: { [key: string]: string };
}

export class ConversationState {
  audience_preview: AudiencePreviewInfo;
  genai_request_id?: string;
  audience_definition_id?: string;
  audience_count_job_id?: string;
  preview_job_id?: string;
  audience_count?: { [key: string]: string };
  query_from_text: string | null;
  options: any | null;
}

export class AudiencePreviewInfo {
  audience_name: string;
  description?: string;
  channel: string;
  audience_type?: string;
  dedupe?: string;
  input_query?: string;
  audience_query?: string;
}

export enum AudienceAIResponseType {
  OK = "OK",
  ERROR = "ERROR"
}

export class ChatConversation {

  chat_history: ChatMessage[] = [];

}

export class AdditionalKwargs{
  previewId?: string;
  sqlClause?: object | string;
  sampleData?: any[];
  tableHeaders?: string[];
}

export class ChatMessage {

  role: UserType;
  content: string;
  options: string[] = [];
  genAIRequestIds: { [key: string]: string };
  currentFeedbackRating = 0;
  previousFeedbackRating = 0;
  showNotification = false;
  isFeebackCommentGiven = false;
  additional_kwargs: AdditionalKwargs;
  response: object;
  llmOutput: string;

  constructor({ role, content, options = [], genAIRequestIds, additionalKwargs, response, llmOutput }:
    { role?: UserType; content?: string; options?: string[]; genAIRequestIds?: { [key: string]: string }; additionalKwargs?: AdditionalKwargs; response?: object; llmOutput?: string } = {}) {
    this.role = role;
    this.content = content;
    this.options = options;
    this.genAIRequestIds = genAIRequestIds;
    this.additional_kwargs = additionalKwargs;
    this.response = response;
    this.llmOutput = llmOutput;
  }

}

export class BaseMessage {

  type: UserType;
  content: string;
  additional_kwargs: { [key: string]: [] };
  response_metadata: { [key: string]: string };

  constructor({ type, content, options, sqlClause, responseType }:
    { type?: UserType; content?: string; options?: { [key: string]: [] }; sqlClause?: { [key: string]: [] }; responseType?: { [key: string]: string } } = {}) {
    this.type = type;
    this.content = content;
    this.additional_kwargs = { ...options, ...sqlClause };
    this.response_metadata = responseType;
  }

}

export class CountResult {
  requestId: string;
  count: number;
  status: string;
  otherIdentityTypeCounts: { [key: string]: string }
}

export class PreviewResult {
  schema: { [key: string]: string };
  data: { [key: string]: string };
  status: string;
}

export class JobResponse {

  version: number;
  createdBy: string;
  createdDate: Date;
  lastModifiedBy: string;
  lastModifiedDate: Date;
  id: string;
  cabContextId: string;
  jobType: string;
  requestParams: { [key: string]: string };
  result: CountResult| PreviewResult;
  status: string;
  submittedAt: Date;
  startedAt: Date;
  completedAt: Date;
  cabTransactionId: string;
}

export enum UserType {
  HUMAN = 'human',
  ASSISTANT = 'ai'
}
