import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TableFilterService } from './table-filter.service';
import { MonitorTableSubType, TableType } from '../audience-overview.models';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { DefinitionsTableComponent } from './definitions-table/definitions-table.component';
import { AudiencesTableComponent } from './audiences-table/audiences-table.component';
import {
  DEFINITION_TXT,
  MONITOR_HISTORY_TXT, MONITOR_SCHEDULED_TXT
} from '../../utils/utils';
import { FEATURE_ASSET_LISTING, FEATURE_SCHEDULING, FEATURE_TAGS_FILTERING } from '../../utils/feature-utils';
import { TabChangeEvent } from '@epsilon/core-ui';
import { get } from 'lodash-es';
import { selectContext } from '../../context/context.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FeatureService } from '../../utils/feature-service';
import { AssetsDefinitionsTableComponent } from './assets-definitions-table/assets-definitions-table.component';
import { AssetsAudiencesTableComponent } from './assets-audiences-table/assets-audiences-table.component';
import { UtilsService } from '../../utils/utilservice';

@UntilDestroy()
@Component({
  selector: 'lib-overview-table',
  templateUrl: './overview-table.component.html',
  styleUrls: ['./overview-table.component.sass'],
})
export class OverviewTableComponent implements AfterViewInit, OnInit {
  productType: string;
  @ViewChild('definitionsTable')
  public definitionsTable: DefinitionsTableComponent;
  @ViewChild('audiencesTable') public audiencesTable: AudiencesTableComponent;
  @ViewChild('assetsDefinitionsTable')
  public assetsDefinitionsTable: AssetsDefinitionsTableComponent;
  @ViewChild('assetsAudiencesTable') 
  public assetsAudiencesTable: AssetsAudiencesTableComponent;
  @ViewChild('saveSuccess', { static: true }) public saveSuccess;
  @ViewChild('listNotFound', { static: true }) public listNotFound;
  @ViewChild('destroySuccess', { static: true }) public destroySuccess;
  tableType$: BehaviorSubject<TableType>;
  monitorTableSubType$: BehaviorSubject<MonitorTableSubType>;
  sendToTargetType$: BehaviorSubject<string>;
  schedulerEnabled: boolean;
  allowTagsEnabled: boolean;
  definitionSaveMessage: string;
  listNotFoundMessage: string;
  enableUIADHActivityListing = false
  isShowFeedTable = false;
  isPaidchannelTypeFeatureEnabled = true;
  successMessage: string;

  constructor(
    public tableFilterService: TableFilterService,
    public store: Store<AppState>,
    public featureService: FeatureService,
    private utilsService: UtilsService,
  ) {
    this.tableType$ = tableFilterService.tableType$;
    this.monitorTableSubType$ = tableFilterService.monitorTableSubType$;
    this.sendToTargetType$ = tableFilterService.sendToTargetType$;

    this.store
    .select(selectContext)
    .pipe(untilDestroyed(this))
    .subscribe((context) => {
      this.productType = context?.productType;
      this.enableUIADHActivityListing = context?.enableUIADHActivityListing;
      if(this.productType) {
        this.schedulerEnabled = this.featureService.isFeatureEnabled(FEATURE_SCHEDULING);
        this.allowTagsEnabled = this.featureService.isFeatureEnabled(FEATURE_TAGS_FILTERING) && this.featureService.isFeatureEnabled(FEATURE_ASSET_LISTING) && this.productType ==='DCDP';
      }
    });
  }

  ngOnInit() {
    if (history?.state?.showDeleteDefinitionSuccess) {
      this.successMessage = history?.state?.audienceDefinitionName + ' has been deleted.';
      this.destroySuccess.show();
    }
    if (history?.state?.showDefinitionSaveSuccess) {
      this.definitionSaveMessage = history?.state?.audienceDefinitionName + ' has been successfully created.';
    }
    if (history?.state?.errorMessage) {
      this.listNotFoundMessage = history?.state?.errorMessage;
    }
    this.sendToTargetType$.subscribe((event)=>{
      this.isShowFeedTable = event === 'owned' && this.enableUIADHActivityListing;
    })
    this.getPaidChannelFlag();
  }

  ngAfterViewInit() {
    if (this.successMessage) {
      this.destroySuccess.show();
    }
    if (this.definitionSaveMessage) {
      this.saveSuccess.show();
    }
    if (this.listNotFoundMessage) {
      this.listNotFound.show();
    }
  }

  public deleteActionMultiple() {
    // pass action to child
    if (this.tableType$.getValue() === DEFINITION_TXT) {
      this.definitionsTable.deleteActionMultiple();
    } else {
      this.audiencesTable.deleteActionMultiple();
    }
  }

  public getPaidChannelFlag() {
    this.utilsService.isPaidChanelFeatureEnabled().pipe(untilDestroyed(this))
      .subscribe((isPaidChannelTypeEnabled) => {
        this.isPaidchannelTypeFeatureEnabled = isPaidChannelTypeEnabled;
      });
  }

  monitorSubTabChange(event: TabChangeEvent) {
    const label = get(event, ['tab', 'label']);
    let monitorSubTabType: MonitorTableSubType = MONITOR_HISTORY_TXT;
    if (label === 'Scheduled') {
      monitorSubTabType = MONITOR_SCHEDULED_TXT;
    }
    this.tableFilterService.monitorTableSubType$.next(monitorSubTabType);
  }

  resetDeleteSuccessMessage() {
    this.successMessage = null;
  }
}
