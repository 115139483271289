import { Component, OnInit, Input } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { selectContext } from '../../context/context.reducer';
import { AppState } from '../../reducers';
import {
  AUDIENCE_TXT,
  DEFINITION_TXT,
} from '../../utils/utils';
import { CabConstants } from '../../cab.constants';
import { checkForSendToTargetType, getConnectorRoute } from '../../audience/audience.utils';
import { CampaignExtract } from '../../campaign-extract/campaign-extract.model';
import { UtilsService } from '../../utils/utilservice';


@UntilDestroy()
@Component({
  selector: 'lib-monitor-overview',
  templateUrl: './monitor-view.component.html',
  styleUrls: ['./monitor-view.component.sass'],
  providers: [DatePipe]

})
export class MonitorOverviewComponent implements OnInit {
  contextId: string;
  audienceId: string;
  isProductTypeDcdp: boolean;
  tenantId: string;
  audienceItem: CampaignExtract;

  @Input() monitor: boolean;
  @Input() data;
  @Input() casAttributeId = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public store: Store<AppState>,
    private datePipe: DatePipe,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.store
    .select(selectContext)
    .pipe(untilDestroyed(this))
    .subscribe((context) => {
      if(context?.productType === CabConstants.DCDP_PRODUCT_NAME) {
        this.isProductTypeDcdp = true;
      }
      this.tenantId = context?.tenantId;
    });
  }

  isAudience(): boolean {
    return this.audienceId != null;
  }

  getDate(date) {
    return date ? this.datePipe.transform(date, 'M/d/YY h:mm a', 'UTC') + ' UTC' : '';
  }

  backToOverview() {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      this.isAudience() ? AUDIENCE_TXT : DEFINITION_TXT,
    ]);
  }

  public checkForSendToDetailsTargetType(data) {
    return checkForSendToTargetType(data);
  }

  public getConnectorRoute(item){
    return getConnectorRoute(item, this.tenantId);
  }
}
