import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, filter, interval, merge, Observable, of, Subject, switchMap, take, takeUntil, timer } from 'rxjs';
import { CabHttpService, ContextType } from '../services/cab-http.service';
import { AudienceAIChatRequest, AudienceAIChatResponse, Conversation, JobResponse } from './audience-ai.model';
import { CabConstants } from '../cab.constants';

@Injectable({ providedIn: 'root'})
export class AudienceAIChatService {

  private stopPolling$ = new Subject<void>();

  constructor(private cabHttpService: CabHttpService, private http: HttpClient) {
  }

  performChat(contextId: string, audienceAIChatRequest: AudienceAIChatRequest, conversationId: string): Observable<AudienceAIChatResponse> {
    const headers = this.buildHeaders(contextId);
    const url = this.cabHttpService.apiUrl(ContextType.CAB, `/cab/proxy/gen-ai/public/audienceai/conversation/` + conversationId);
    return this.http.post(url, audienceAIChatRequest, { headers }) as Observable<AudienceAIChatResponse>;
  }

  newConversation(contextId: string, dataUniverseId: string, userSessionId: string): Observable<Conversation> {
    const body = {
        [CabConstants.DATA_UNIVERSE_ID]:  dataUniverseId,
        [CabConstants.USER_SESSION_ID]:  userSessionId,
    };
    const headers = this.buildHeaders(contextId);
    const url = this.cabHttpService.apiUrl(ContextType.CAB, `/cab/proxy/gen-ai/public/audienceai/conversation`);
    return this.http.post(url, body, { headers }) as Observable<Conversation>;
  }

  finishConversation(contextId: string, conversationId: string): Observable<Conversation> {
    const headers = this.buildHeaders(contextId);
    const url = this.cabHttpService.apiUrl(ContextType.CAB, `/cab/proxy/gen-ai/public/audienceai/conversation/` + conversationId + '/finish');
    return this.http.post(url, null,{ headers }) as Observable<Conversation>;
  }

  getConversation(contextId: string, conversationId: string): Observable<Conversation> {
    const headers = this.buildHeaders(contextId);
    const url = this.cabHttpService.apiUrl(ContextType.CAB, `/cab/proxy/gen-ai/public/audienceai/conversation/` + conversationId);
    return this.http.get(url, { headers }) as Observable<Conversation>;
  }

  getJobStatus(jobId: string, contextId: string): Observable<JobResponse> {
    const headers = this.buildHeaders(contextId);
    const jobStatusUrl = this.cabHttpService.apiUrl(ContextType.CAB, `/cab/v1/job/`);
    return this.http.get<JobResponse>(`${jobStatusUrl}${jobId}`, { headers });
  }

  pollJobStatus(jobId: string, contextId: string): Observable<JobResponse> {
    const timeout = 100000
    return interval(1000)
      .pipe(
        switchMap(() => this.getJobStatus(jobId, contextId)),
        filter(response => response.status === 'SUCCESS' || response.status === 'FAILURE'),
        take(1),
        takeUntil(merge(timer(timeout), this.stopPolling$)),
        catchError((error) => {
          console.error('Error fetching job status:', error);
          return of(null); // Continue polling in case of error
        })
      );
  }

  cancelPolling() {
    this.stopPolling$.next();
    this.stopPolling$.complete();
    this.stopPolling$ = new Subject<void>();
  }

  updateCount(contextId: string, conversationId: string, count: string): Observable<Conversation> {
    const body = { "count": count };
    const headers = this.buildHeaders(contextId);
    const url = this.cabHttpService.apiUrl(ContextType.CAB, `/cab/proxy/gen-ai/public/audienceai/conversation/` + conversationId + '/update_count');
    return this.http.put(url, body, { headers }) as Observable<Conversation>;
  }

  private buildHeaders(contextId: string) {
    return {
      [CabConstants.CAB_CONTEXT_HEADER]:  contextId,
    };
  }

}
