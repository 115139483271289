  <lib-overview-header  *ngIf="(tableType$ | async) !== 'monitor'"  (deleteActionForMultiple)="deleteActionMultiple()"></lib-overview-header>
  <lib-activity-table  *ngIf="(tableType$ | async) === 'deliveries' && productType === 'DCDP' && !isShowFeedTable && isPaidchannelTypeFeatureEnabled"></lib-activity-table>
  <lib-feed-data-table *ngIf="(tableType$ | async) === 'deliveries' && productType === 'DCDP' && isShowFeedTable"></lib-feed-data-table>
  <lib-definitions-table
    #definitionsTable
    *ngIf="(tableType$ | async) === 'definition' && !allowTagsEnabled"
  ></lib-definitions-table>
  <lib-audiences-table
    #audiencesTable
    *ngIf="(tableType$ | async) === 'audience' && !allowTagsEnabled"
  ></lib-audiences-table>
  <lib-assets-definitions-table
    #assetsDefinitionsTable
    *ngIf="(tableType$ | async) === 'definition' && allowTagsEnabled"
  ></lib-assets-definitions-table>
  <lib-assets-audiences-table
    #assetsAudiencesTable
    *ngIf="(tableType$ | async) === 'audience' && allowTagsEnabled"
  ></lib-assets-audiences-table>
  <div *ngIf="!schedulerEnabled && (tableType$ | async) === 'monitor'">
    <lib-overview-header></lib-overview-header>
    <lib-monitor-table #monitorTable></lib-monitor-table>
  </div>
  <coreui-tab-group tabStyle="secondary" (tabChange)="monitorSubTabChange($event)" *ngIf="(tableType$ | async) === 'monitor' && schedulerEnabled ">
    <coreui-tab label="History" [active]="tableFilterService.monitorTableSubType$.value === 'history'">
      <div class="tabs" *ngIf="(monitorTableSubType$ | async) === 'history'" >
        <div class="history-container">
          <lib-overview-header ></lib-overview-header>
        </div>
        <div class="scheduled-container">
          <lib-monitor-table #monitorTable></lib-monitor-table>
        </div>
      </div>
    </coreui-tab>
    <coreui-tab label="Scheduled" [active]="tableFilterService.monitorTableSubType$.value === 'scheduled'">
      <div class="" *ngIf="(monitorTableSubType$ | async) === 'scheduled'">
        <div class="schedule-overview-header">
          <lib-overview-header [ismonitorScheduleTab] = true ></lib-overview-header>
        </div>
        <lib-schedule-table #scheduleTable>
        </lib-schedule-table>
      </div>
    </coreui-tab>
  </coreui-tab-group>

  <coreui-notification notificationStyle="" (hidden)="resetDeleteSuccessMessage()" #destroySuccess>
    {{successMessage}}
  </coreui-notification>

  <coreui-notification notificationStyle="success" [isPersistent]="false" #saveSuccess>
    {{definitionSaveMessage}}
  </coreui-notification>

  <coreui-notification notificationStyle="error" [isPersistent]="false" #listNotFound>
    {{listNotFoundMessage}}
  </coreui-notification>