import { Routes } from '@angular/router';
import { CabComponent } from './cab.component';
import { AudienceOverviewComponent } from './audience-overview/audience-overview.component';
import { DetailsComponent } from './audience-overview/details/details.component';
import { AdminComponent } from './admin/admin.component';
import { DatasetComponent } from './admin/dataset/dataset.component';
import { EnumerationComponent } from './admin/enumerations/enumeration.component';
import { AudienceBuilderBaseComponent } from './audience-builder-base/audience-builder-base.component';
import { AmsActivationWrapperComponent } from './audience-overview/overview-table/ams-activation-wrapper/ams-activation-wrapper.component';
import { TenantAuthActivateGuard, TenantAuthActivateGuardChild } from './guard/tenant-auth-activate.guard';
import { TenantComponent } from './tenant/tenant/tenant.component';
import { AudienceDefinitionResolver } from './resolver/audience-definition.resolver';
import { MonitorResolver } from './resolver/monitor.resolver';
import { AudienceResolver } from './resolver/audience.resolver';
import { SendToComponent } from './audience-overview/overview-table/send-to/send-to.component';
import { CanDeactivateGuard } from './guard/can-deactivate.guard';
import { activationRoutes } from '@epsilon-ams/ams-core-activation';
import { AudienceAIDailogComponent } from './audience-ai/audience-ai-dailog/audience-ai-dailog.component';
import { PageNotFoundGuard } from './guard/page-not-found.guard';
import { ManageTrackedComponent } from './manage-tracked/manage-tracked.component';
import { CreateTrackedAttributesComponent } from './manage-tracked/create-tracked-attributes/create-tracked-attributes.component';

const cabAdminRoutes: Routes = [
  {
    path: '',
    component: AdminComponent,
  },
  {
    path: 'enumerations',
    component: AdminComponent,
  },
  { path: 'enumeration/create', component: EnumerationComponent },
  { path: 'enumeration/:enumId/edit', component: EnumerationComponent },
  {
    path: 'dataset/:datasetId/edit',
    component: DatasetComponent,
  },
];

const cabDataUniverseRoutes: Routes = [
  {
    path: ':id/ams',
    component: AmsActivationWrapperComponent,
  },
  {
    path: ':id/sendTo',
    component: SendToComponent,
  },
  {
    path: ':id/cab/sendTo',
    component: SendToComponent,
  },
  {
    path: 'audience/view/:audienceId',
    component: DetailsComponent,
    resolve: { audienceData: AudienceResolver }
  },
  {
    path: 'builder',
    component: AudienceBuilderBaseComponent,
  },
  {
    path: 'ai-dailog/:conversationId',
    component: AudienceAIDailogComponent
  },
  {
    path: 'builder/edit/:definitionId',
    component: AudienceBuilderBaseComponent,
    resolve: { audienceData: AudienceDefinitionResolver }
  },
  {
    path: 'builder/view/:definitionId',
    component: AudienceBuilderBaseComponent,
    resolve: { audienceData: AudienceDefinitionResolver }
  },
  {
    path: 'definition/view/:definitionId',
    component: DetailsComponent,
    resolve: { audienceData: AudienceDefinitionResolver }
  },
  {
    path: 'monitor/view/:campaignExtractId',
    component: DetailsComponent,
    resolve: { audienceData: MonitorResolver }
  },
  {
    path: '**',
    canActivate: [PageNotFoundGuard],
    children: []
  },
];

const cabContextRoutes: Routes = [
  {
    path: '',
    component: CabComponent
  },
  {
    path: 'admin',
    children: cabAdminRoutes,
  },
  {
    path: 'definition',
    component: AudienceOverviewComponent,
  },
  {
    path: 'deliveries',
    component: AudienceOverviewComponent,
  },
  {
    path: 'audience',
    component: AudienceOverviewComponent,
  },
  {
    path: 'monitor',
    component: AudienceOverviewComponent,
  },
  {
    path: 'manage-tracked',
    component: ManageTrackedComponent,
  },
  {
    path: 'tracked-attributes',
    component: CreateTrackedAttributesComponent,
  },
  {
    path: 'tracked-attributes/:templateId',
    component: CreateTrackedAttributesComponent,
  },
  {
    path: ':dataUniverseId',
    children: cabDataUniverseRoutes,
  },
  {
    path: 'ams-activations',
    children: [...activationRoutes]
  },
];

export const cabRootRoutes: Routes = [
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [TenantAuthActivateGuard],
    component: TenantComponent
  },
  {
    path: ':contextId',
    children: cabContextRoutes,
    runGuardsAndResolvers: 'always',
    canActivateChild: [
      TenantAuthActivateGuardChild],
    canDeactivate:[CanDeactivateGuard]
  },
];

export const cabRoutes: Routes = [
  {
    path: '',
    component: CabComponent,
    children: cabRootRoutes
  }
];
