import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { AppState } from '../reducers';
import { selectContext } from '../context/context.reducer';
import { DedupeType } from '../models/dedupe-type';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { CountsService } from '../counts/counts.service';
import { CabConstants } from '../cab.constants';

@UntilDestroy()
@Component({
  selector: 'lib-builder-form',
  templateUrl: './builder-form.component.html',
  styleUrls: ['./builder-form.component.sass'],
})
export class BuilderFormComponent implements OnInit, OnChanges {
  @ViewChild('updateCancleCountModel')
  public updateCancleCountModel;
  @Input() audienceForm: UntypedFormGroup;
  @Input() isEdit: boolean;
  @Output() submitUpdateCount: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() handleInvalidDefinitionError: EventEmitter<any> =
    new EventEmitter<any>();
  @Input() activeDedupeType: DedupeType;
  @Input() updateCountSuccess: boolean;
  @ViewChild('countUpdated') countUpdated;
  errorOnLoadState$ = new BehaviorSubject<boolean>(false);
  isProductTypeDcdp: boolean;

  constructor(
    public builderService: AudienceBuilderService,
    public countsService: CountsService,
    public store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        if(context?.productType === CabConstants.DCDP_PRODUCT_NAME) {
          this.isProductTypeDcdp = true;
        }
      });
  }

  ngOnChanges(): void {
    if(this.updateCountSuccess) {
      this.countUpdated?.show();
    }
  }

  triggerSubmitUpdateCount(event) {
    this.submitUpdateCount.emit(event);
  }

  triggerHandleInvalidDefinitionError() {
    this.handleInvalidDefinitionError.emit();
  }

  canSaveDefinition() {
    this.builderService.checkForAttributeValues();
    return this.isAudienceValid();
  }

  isAudienceValid() {
    return (
      this.builderService.definitionHasAttribute$.value &&
      this.builderService.allExpressionsHaveValues$.value &&
      this.audienceForm.valid
    );
  }

  isSaveButtonDisabled() {
    return !this.builderService.definitionHasAttribute$.value;
  }

  cancelCount(){
    this.updateCancleCountModel.show();
  }

  toggleCancel(type: string){
    if(type === 'continue'){
      this.countsService.stopCron();
    }
    this.updateCancleCountModel.hide();
  }
}
