import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { UtilsService } from '../../utils/utilservice';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageTrackedService } from '../manage-tracked.service';
import { isDefined } from '../../utils/utils';
import { cloneDeep, find, orderBy } from 'lodash-es';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { combineLatest, filter } from 'rxjs';
import {
  selectActiveDataUniverse,
  selectDataUniverses,
  selectDedupeTypes
} from '../../data-universe/data-universe.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataUniverse } from '../../data-universe/data-universe.models';
import { LoadingIndicatorComponent } from '@epsilon/core-ui';
import { cabPathList } from '../../audience/audience.utils';
import { NavTreeNode } from '@epsilon/pcbc-nav-tree';
import { DedupeType } from '../../models/dedupe-type';

@UntilDestroy()
@Component({
  selector: 'lib-create-tracked-attributes',
  templateUrl: './create-tracked-attributes.component.html',
  styleUrl: './create-tracked-attributes.component.sass'
})
export class CreateTrackedAttributesComponent implements OnInit {
  @ViewChild(LoadingIndicatorComponent, { static: true })
  public loading: LoadingIndicatorComponent;

  trackedAttributeForm: FormGroup;
  contextId: string;
  currentStep = 'defineAttributeSet';
  allTrackedAttributeAsKeyValueMap = {};
  showSelectAttributeDrawer = false;
  activeDataUniverse: DataUniverse;
  activeDataUniverse$ = this.store.select(selectActiveDataUniverse);
  selectedAttributeNodes = [];
  public attributeSearchForm = new FormGroup({
    attributeSearchFormControl: new FormControl(),
    showFullPath: new FormControl()
  });
  recordLimit = 1000;
  breadcrumbs = [
    {
      displayName: 'All Criteria'
    }
  ];
  attributeLoader = false;
  showSelectAttributeError = false;
  isProspectEnabled = false;

  showSearchTree = false;
  searchText = '';
  showSearchLimit = false;
  isRegularSearch = false;
  currentNode;
  rootNodes = [];
  searchNodes: NavTreeNode[] = [];

  templateId = '';
  currentTrackedAttribute;
  hasPendingTrackedAttributes = false;

  constructor(
    public store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService,
    private manageTrackedService: ManageTrackedService
  ) {}

  ngOnInit(): void {
    this.templateId = this.route.snapshot.paramMap.get('templateId');
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.getTrackedAttributes();
    combineLatest([
      this.store.select(selectDataUniverses),
      this.activeDataUniverse$.pipe(filter(isDefined))
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([dataUniverses, activeDataUniverse]) => {
        this.activeDataUniverse = find(dataUniverses, {
          id: activeDataUniverse.id
        });
        this.store
          .select(selectDedupeTypes(this.activeDataUniverse.id))
          .subscribe((dedupeTypes) => {
            this.isProspectEnabled = dedupeTypes
              .map((item: DedupeType) => item.dataSetId)
              .includes('Prospect');
          });
      });
  }

  getTrackedAttributes() {
    const body = {
      cabContextId: this.contextId
    };
    this.allTrackedAttributeAsKeyValueMap = {};
    this.loading.show();
    this.manageTrackedService.getTrackedAttributes(body).subscribe({
      next: (res: any) => {
        if (res?.results?.length) {
          this.allTrackedAttributeAsKeyValueMap =
            this.createTrackedAttributeKeyValuePair(res.results);
        } else {
          this.allTrackedAttributeAsKeyValueMap = {};
        }
        this.createTrackedAttributeForm();
        if (this.templateId) {
          this.getTrackedAttributeByTemplateId();
        }
        this.loading.hide();
      },
      error: (err) => {
        console.log(err);
        this.loading.hide();
      }
    });
  }

  createTrackedAttributeKeyValuePair(trackedAttribute) {
    const result = {};
    trackedAttribute.forEach((d) => {
      if (d.templateName) {
        result[d.templateName.trim().toLowerCase()] = true;
      }
    });
    return result;
  }

  getTrackedAttributeByTemplateId() {
    this.loading.show();
    this.manageTrackedService
      .getTrackedAttributeById(this.templateId)
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.currentTrackedAttribute = res;
            this.updateTrackedAttribute();
          }
          this.loading.hide();
        },
        error: (err) => {
          console.log(err);
          this.loading.hide();
        }
      });
  }

  hasAttributeSetNameAlready(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control?.value ? control.value.trim().toLowerCase() : null;
      if (value && this.allTrackedAttributeAsKeyValueMap[value]) {
        return { hasAttributeSetNameAlready: true };
      }
      return null;
    };
  }

  createTrackedAttributeForm() {
    this.trackedAttributeForm = new FormGroup({
      attributeSetName: new FormControl('', [
        Validators.required,
        this.hasAttributeSetNameAlready()
      ]),
      attributeSetType: new FormControl('Profile', [Validators.required])
    });
  }

  updateTrackedAttribute() {
    this.trackedAttributeForm.patchValue({
      attributeSetName: this.currentTrackedAttribute.templateName,
      attributeSetType:
        this.currentTrackedAttribute.audienceType === 'PROFILE'
          ? 'Profile'
          : 'Prospect'
    });

    const completedTrackedAttributes =
      this.currentTrackedAttribute.trackedAttributes?.map((node) => {
        const pathString = node.path.map((d) => d.displayName);
        pathString.shift();
        node.fullPath = pathString.join(' / ');
        node.status = 'active';
        return node;
      });
    let pendingTrackedAttributes = [];
    this.currentStep = 'chooseAttributes';
    this.attributeSearchForm.get('showFullPath').setValue(true);
    if (this.currentTrackedAttribute.pendingTrackedAttributes?.length) {
      this.hasPendingTrackedAttributes = true;
      pendingTrackedAttributes =
        this.currentTrackedAttribute.pendingTrackedAttributes?.map((node) => {
          const pathString = node.path.map((d) => d.displayName);
          pathString.shift();
          node.fullPath = pathString.join(' / ');
          node.status = 'pending';
          return node;
        });
    }
    this.selectedAttributeNodes = [
      ...(pendingTrackedAttributes || []),
      ...(completedTrackedAttributes || [])
    ];
  }

  onAttributeSetTypeTabValueChange(currentTabValue) {
    this.trackedAttributeForm
      .get('attributeSetType')
      .setValue(currentTabValue.tabLabel);
  }

  onNextStep() {
    if (this.currentStep === 'defineAttributeSet') {
      this.trackedAttributeForm.markAllAsTouched();
      if (this.trackedAttributeForm.valid) {
        this.currentStep = 'chooseAttributes';
      }
    } else if (this.currentStep === 'chooseAttributes') {
      if (this.selectedAttributeNodes.length) {
        this.createTrackedAttribute();
      } else {
        this.showSelectAttributeError = true;
      }
    }
  }

  createTrackedAttribute() {
    const reqBody = {
      audienceType:
        this.trackedAttributeForm.get('attributeSetType').value === 'Profile'
          ? 'PROFILE'
          : 'PROSPECT',
      templateName: this.trackedAttributeForm.get('attributeSetName').value,
      createAttributes: this.selectedAttributeNodes
        .filter((node) => {
          return !this.currentTrackedAttribute?.trackedAttributes.find(
            (d) => node.cabId === d.cabId
          )?.cabId;
        })
        .map((d) => ({
          cabId: d.cabId,
          dataType: d.dataType,
          nativeDataType: d.nativeDataType,
          path: d.path
        }))
    };
    this.loading.show();
    let apiCall;
    if (this.currentTrackedAttribute) {
      if (
        this.currentTrackedAttribute &&
        this.currentTrackedAttribute.trackedAttributes?.length
      ) {
        reqBody['deleteAttributes'] =
          this.currentTrackedAttribute.trackedAttributes.filter((node) => {
            return !this.selectedAttributeNodes.find(
              (d) => node.cabId === d.cabId
            )?.cabId;
          });
      }
      reqBody['id'] = this.currentTrackedAttribute.id;
      reqBody['version'] = this.currentTrackedAttribute.version;
      apiCall = this.manageTrackedService.updateTrackedAttribute(
        reqBody,
        this.currentTrackedAttribute.id
      );
    } else {
      apiCall = this.manageTrackedService.createTrackedAttribute(reqBody);
    }
    apiCall.subscribe({
      next: (data) => {
        this.loading.hide();
        console.log(data);
        this.goToManageTrackedAttributes();
      },
      error: (err) => {
        this.loading.hide();
        console.log(err);
      }
    });
  }

  goBack() {
    if (this.currentStep === 'defineAttributeSet') {
      this.goToManageTrackedAttributes();
    } else if (this.currentStep === 'chooseAttributes') {
      this.currentStep = 'defineAttributeSet';
    }
  }

  goToManageTrackedAttributes() {
    this.router.navigate([
      this.utilsService.getProductBaseUrl(this.router, this.route),
      this.contextId,
      'manage-tracked'
    ]);
  }

  resetTrackedAttributeForm() {
    if (this.currentStep === 'defineAttributeSet') {
      if (this.currentTrackedAttribute) {
        this.trackedAttributeForm.reset();
        this.updateTrackedAttribute();
      } else {
        this.trackedAttributeForm.reset();
        this.trackedAttributeForm.get('attributeSetType').setValue('Profile');
      }
    } else if (this.currentStep === 'chooseAttributes') {
      this.currentStep = 'defineAttributeSet';
    }
  }

  onShowSelectAttributeDrawer() {
    this.showSelectAttributeError = false;
    this.showSelectAttributeDrawer = true;
    this.breadcrumbs = [
      {
        displayName: 'All Criteria'
      }
    ];
    this.attributeSearchForm.get('attributeSearchFormControl').setValue('');
    this.showSearchTree = false;
    this.currentNode = null;
    this.fetchRootAttributes();
  }

  onAttributeOptionClicked(node) {
    this.attributeSearchForm.get('attributeSearchFormControl').setValue('');
    this.showSearchTree = false;
    this.fetchChildAttributes(node);
  }

  get attributeOptions() {
    return this.showSearchTree ? this.searchNodes : this.rootNodes;
  }

  fetchRootAttributes() {
    this.attributeLoader = true;
    this.manageTrackedService
      .fetchAttributes(
        this.contextId,
        this.activeDataUniverse.id,
        this.trackedAttributeForm.get('attributeSetType').value,
        this.recordLimit,
        []
      )
      .subscribe({
        next: (response) => {
          const rootNodes = response.results;
          this.rootNodes = rootNodes.filter(
            (d) =>
              d.dataSetType !== 'AUDIENCE_DEFINITION_CATEGORY' &&
              d.dataSetType !== 'AUDIENCE_LIST_CATEGORY'
          );
          this.attributeLoader = false;
        },
        error: (err) => {
          console.log(err);
          this.attributeLoader = false;
        }
      });
  }

  fetchChildAttributes(navTreeNode, breadcrumbEvent = false) {
    this.attributeLoader = true;
    this.manageTrackedService
      .fetchAttributes(
        this.contextId,
        this.activeDataUniverse.id,
        this.trackedAttributeForm.get('attributeSetType').value,
        this.recordLimit,
        navTreeNode['path'],
        navTreeNode['cabId']
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: any) => {
          if (!breadcrumbEvent) {
            this.breadcrumbs.push(navTreeNode);
          }
          const childNodes = response['results'];
          let orderedNodes = [];
          navTreeNode.children = [];
          orderedNodes = childNodes;
          orderedNodes.forEach((node) => navTreeNode.children.push(node));
          this.currentNode = navTreeNode;
          this.rootNodes = cloneDeep(navTreeNode.children ?? []);
          this.attributeLoader = false;
        },
        error: (error) => {
          console.log(error);
          this.attributeLoader = false;
        }
      });
  }

  onBreadcrumbClicked(breadcrumbIndex) {
    this.navigateToLink(breadcrumbIndex === 0 ? null : breadcrumbIndex);
  }

  navigateToLink(bIndex) {
    this.searchNodes = [];
    this.showSearchTree = false;
    if (bIndex !== null) {
      this.fetchChildAttributes(this.breadcrumbs[bIndex], true);
      this.breadcrumbs = this.breadcrumbs.slice(0, bIndex + 1);
    } else {
      this.breadcrumbs = [
        {
          displayName: 'All Criteria'
        }
      ];
      this.currentNode = null;
      this.fetchRootAttributes();
    }
  }

  resetAttributeSearch() {
    this.attributeSearchForm.get('attributeSearchFormControl').setValue('');
    this.onSearchUpdated();
  }

  onSearchUpdated() {
    const searchText = this.attributeSearchForm.get(
      'attributeSearchFormControl'
    ).value;
    if (searchText.length) {
      this.searchText = searchText;
      this.attributeSearch();
    } else {
      this.clearAttributeSearch();
    }
  }

  attributeSearch() {
    const startIndex = 0;
    this.searchNodes = [];
    if (this.searchText.trim().length < 3) {
      this.showSearchLimit = true;
      return;
    }
    this.showSearchLimit = false;
    if (!Object.keys(cabPathList).includes(this.currentNode['cabId'])) {
      if (this.rootNodes.length <= 1000) {
        this.searchNodes = this.rootNodes.filter((node) =>
          node.displayName.toLowerCase().includes(this.searchText.toLowerCase())
        );
        this.showSearchTree = true;
        this.isRegularSearch = true;
        return;
      }
    }
    this.recordLimit = 1000;
    this.attributeLoader = true;
    this.manageTrackedService
      .searchAttributes(
        this.contextId,
        this.activeDataUniverse.id,
        this.trackedAttributeForm.get('attributeSetType').value,
        this.searchText,
        'DATA_SET',
        this.currentNode?.cabId,
        this.rootNodes.length > 1000 ? this.currentNode['path'] : null,
        this.recordLimit,
        startIndex,
        null
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          this.searchNodes = cloneDeep(response.results);
          const childNodes = this.searchNodes;
          this.searchNodes = orderBy(childNodes, ['leaf', 'displayName']);
          this.showSearchTree = true;
          this.attributeLoader = false;
        },
        error: (err) => {
          this.attributeLoader = false;
          console.log(err);
        }
      });
  }

  clearAttributeSearch() {
    this.showSearchTree = false;
    this.isRegularSearch = false;
    this.searchText = '';
    this.showSearchLimit = false;
    if (this.currentNode) {
      this.currentNode.children = [];
      this.fetchChildAttributes(this.currentNode, true);
    }
  }

  addNode(node) {
    if (
      this.selectedAttributeNodes.find((d) => d.cabId === node.cabId)?.cabId
    ) {
      return;
    }
    if (node.path.length) {
      const pathString = node.path.map((d) => d.displayName);
      pathString.shift();
      node.fullPath = pathString.join(' / ');
    }
    this.selectedAttributeNodes.push(node);
    if (this.selectedAttributeNodes.length === 20) {
      this.showSelectAttributeDrawer = false;
    }
  }

  removeNode(node) {
    this.selectedAttributeNodes = this.selectedAttributeNodes.filter(
      (d) => d.cabId != node.cabId
    );
  }
}
