import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationComponent, ThemeColorService } from '@epsilon/core-ui';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AudienceDefinitionGenerateAIRating } from '../../audience-builder/audience-builder.models';
import { AudienceBuilderService } from '../../audience-builder/audience-builder.service';
import { CabConstants } from '../../cab.constants';
import { ConfirmationPopupComponent } from '../../shared/components/confirmation-popup/confirmation-popup.component';
import { AudienceAIChatService } from '../audience-ai-chat.service';
import {
  AudienceAIChatRequest,
  AudienceAIChatResponse,
  AudienceAIResponseType,
  ChatConversation,
  ChatMessage,
  Conversation,
  JobResponse,
  UserType,
} from '../audience-ai.model';
import { UtilsService } from '../../utils/utilservice';
import { AUDIENCE_AI_DAILOG_TXT } from '../../utils/utils';
import { lastValueFrom } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'lib-audience-ai-dailog',
  templateUrl: './audience-ai-dailog.component.html',
  styleUrls: ['./audience-ai-dailog.component.sass'],
})
export class AudienceAIDailogComponent implements OnInit {
  isQueryRunning = false;
  human = UserType.HUMAN;
  assistant = UserType.ASSISTANT;
  audienceAIChatRequest: AudienceAIChatRequest;
  chatConversations: ChatConversation;
  audienceAIFormGroup: UntypedFormGroup;
  contextId: string;
  confirmationTitle = 'Restart the Audience AI definition';
  customMessage =
    "Restarting the Audience AI definition will cause you to lose all progress. Are you sure you want to continue?";
  confirmText = 'Continue';
  cancelText = 'Cancel';
  isUserInputValid = true;
  savedAudienceDefinitionId: string;
  dataUniverseId: string;
  @ViewChild('chatMessages') public chatContainer!: ElementRef | undefined;
  @ViewChild('previewContent') private previewContainer!:
    | ElementRef
    | undefined;
  @ViewChild(ConfirmationPopupComponent)
  private confirmationPopup: ConfirmationPopupComponent;
  @ViewChild('toastError', { static: true })
  public toastError: NotificationComponent;
  errorMessage: string;
  conversationId: string;
  userSessionId: string;
  conversation: any;
  currentTheme: number;
  count: string;
  isCountCancelled = false;
  showCancel = false;
  jobType: string;
  sample_data: { [key: string]: any }[];
  isScrollAtBottom = true;
  scrollTimeout = null;
  notificationStyle: string;
  notificationMessage: string;
  feedbackSuccessMessage = "Your feedback has been submitted.";
  feedbackErrorMessage = "There is an error while submitting the feedback.";
  countCancelledMessage = "Run count has been canceled. Let me know when you're ready to run it again.";
  countErrorMessage = "Failed to fetch the Count.";
  previewCancelledMessage = "Previewing audinece definition has been canceled. Let me know when you're ready to run it again.";
  previewErrorMessage = 'Failed to fetch the Sample Data';

  constructor(
    @Inject('APP_CONFIG') private appConfigService: any,
    public audienceAIChatService: AudienceAIChatService,
    public builderService: AudienceBuilderService,
    public utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private themeColorService: ThemeColorService) {}

  ngOnInit(): void {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.dataUniverseId = this.route.snapshot.paramMap.get('dataUniverseId');
    this.conversationId = this.route.snapshot.paramMap.get('conversationId');
    this.userSessionId = localStorage.getItem('userSessionId');
    localStorage.removeItem('userSessionId')
    this.initialize();
    this.onPageReload();
    this.themeColorService.getThemeMap().subscribe(() => {
      this.currentTheme = this.themeColorService.getTheme();
    });
    this.collapseLeftNavMenu();
  }

  private collapseLeftNavMenu(): void {
    if (this.appConfigService?.setToggleForLeftNavMenu instanceof Function) {
      this.appConfigService?.setToggleForLeftNavMenu(true);
    }
  }

  private initialize(): void {
    this.audienceAIChatRequest = new AudienceAIChatRequest();
    this.audienceAIChatRequest.data_universe_id = this.dataUniverseId;
    this.audienceAIChatRequest.business_unit_id = this.getBusinessUnitId();
    this.chatConversations = new ChatConversation();
    this.audienceAIFormGroup = this.buildAudienceAIFormGroup();
    this.isUserInputEnable();
  }

  private buildAudienceAIFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      chatInput: new UntypedFormControl(''),
      previewData: this.initPreviewDataFormGroup(),
      commentMessage: new UntypedFormControl(''),
    });
  }

  private getBusinessUnitId() {
    const businessUnitId = this.utilsService.getBusinessUnitId(this.route);
    return businessUnitId != 'DEFAULT' ? businessUnitId : null;
  }

  private onPageReload() : void {
    if (sessionStorage.getItem('conversationType')) {
      this.initiateChat();
    } else {
      this.collapseLeftNavMenu();
      this.getConversation();
    }
  }

  private getConversation(): void {
    this.isQueryRunning = true;
    this.chatConversations.chat_history = [];
    const url = this.route.snapshot.url.join('/');
    this.userSessionId = url.split('/').pop();
    this.audienceAIChatService.getConversation(this.contextId, this.userSessionId)
    .subscribe({
      next: (response: Conversation) => {
        response.chat_history.forEach((message) => {
          if (message.response_metadata["response_type"] === "ERROR")
            this.chatConversations.chat_history.pop()
          else {
            const chatMessage = new ChatMessage({
              role: message.type === "human" ? UserType.HUMAN : UserType.ASSISTANT,
              content: message.content,
              options: message.additional_kwargs['options'] || [],
              additionalKwargs: { "sqlClause": message.additional_kwargs['sqlClause'] },
              response: { "response_type": message.response_metadata["response_type"] }
            });
            this.buildChatConversations(chatMessage);
          }
        });
        this.scrollToBottom(this.chatContainer);
        this.count = response.conversation_state.audience_count?.["count"];
        const previewDataFormGroup = this.buildPreviewDataFormGroup(response.conversation_state.audience_preview);
        this.scrollToBottom(this.previewContainer);
        this.audienceAIFormGroup.setControl('previewData', previewDataFormGroup);
        this.isQueryRunning = false;
        this.isUserInputEnable();
      },
      error: (error) => {
        console.error('Error fetching all the conversations', error);
        this.isQueryRunning = false;
      }
    });
  }

  public goToNewAudienceAIChatDailog(userSessionId: string): void {
    this.audienceAIChatService.newConversation(this.contextId, this.dataUniverseId, userSessionId)
    .subscribe({
      next: (response: Conversation) => {
        this.conversation = response;
        this.conversationId = this.conversation.id;
        this.userSessionId = this.conversation.userSessionId;
        this.router.navigate([
          this.utilsService.getProductBaseUrl(this.router, this.route),
          this.contextId,
          this.dataUniverseId,
          AUDIENCE_AI_DAILOG_TXT,
          this.conversationId
        ]).then(() => {
          this.initiateChat();
        });
      },
      error: (error) => {
        console.error('Error fetching conversations', error);
        this.errorMessage = "Server Error. Please try after sometime.";
        this.showToast(this.toastError);
      }
    });
  }

  private initPreviewDataFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      audience_name: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      channel: new UntypedFormControl(''),
      audience_type: new UntypedFormControl(''),
      dedupe: new UntypedFormControl(''),
      input_query: new UntypedFormControl(''),
      audience_query: new UntypedFormControl(''),
    });
  }

  public initiateChat(): void {
    const chatMessage = new ChatMessage({
      role: UserType.HUMAN,
      content: CabConstants.AUDIENCE_AI_CHAT_INITIATE_MESSAGE,
    });
    this.buildChatConversations(chatMessage);
    this.audienceAIChatRequest.input_message =
      CabConstants.AUDIENCE_AI_CHAT_INITIATE_MESSAGE;
    this.performChat(this.audienceAIChatRequest);
    this.updateChatMessages(
      CabConstants.AUDIENCE_AI_CHAT_INITIATE_MESSAGE,
      UserType.HUMAN
    );
    sessionStorage.removeItem('conversationType')
  }

  public submitQuery(): void {
    if (
      !this.audienceAIFormGroup.get('chatInput').value ||
      this.audienceAIFormGroup.get('chatInput').value.trim().length === 0 ||
      this.audienceAIFormGroup.get('chatInput').errors ||
      this.isQueryRunning
    ) {
      return;
    }
    const userMessage = this.audienceAIFormGroup.get('chatInput').value;
    const chatMessage = new ChatMessage({
      role: UserType.HUMAN,
      content: userMessage,
    });
    this.buildChatConversations(chatMessage);
    this.audienceAIChatRequest.input_message = userMessage;
    this.performChat(this.audienceAIChatRequest);
    this.updateChatMessages(userMessage, UserType.HUMAN);
    this.audienceAIFormGroup.get('chatInput').reset();
  }

  private buildChatConversations(chatMessage: ChatMessage) {
    this.chatConversations.chat_history.push(chatMessage);
  }

  public scrollToBottom(container: ElementRef): void {
    if (this.chatContainer && this.previewContainer) {
      setTimeout(() => {
        container.nativeElement.scrollTo({
          top: this.chatContainer.nativeElement.scrollHeight,
          behavior: 'smooth'
        });
        this.isScrollAtBottom = true;
      }, 0);
    }
  }

  public onScroll(container: ElementRef) {
    const chatContainer = container.nativeElement;
    const threshold = 25;
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this.scrollTimeout = setTimeout(() => {
      this.isScrollAtBottom =
        chatContainer.scrollHeight - chatContainer.scrollTop <=
        chatContainer.clientHeight + threshold;
    }, 300);
  }

  private updateChatMessages(message: string, userType: UserType): void {
    const chatMessage = new ChatMessage({ role: userType, content: message });
    this.audienceAIChatRequest.chat_history.push(chatMessage);
    this.scrollToBottom(this.chatContainer);
  }

  public clearChat(): void {
    this.audienceAIChatRequest = new AudienceAIChatRequest();
    this.audienceAIChatRequest.data_universe_id = this.dataUniverseId;
    this.audienceAIChatRequest.business_unit_id = this.getBusinessUnitId();
    this.chatConversations = new ChatConversation();
    this.audienceAIFormGroup.reset();
    this.audienceAIFormGroup.setControl(
      'previewData',
      new UntypedFormGroup({})
    );
    this.isQueryRunning = false;
    this.audienceAIChatService.finishConversation(this.contextId, this.conversationId).subscribe(() => {
      this.goToNewAudienceAIChatDailog(this.userSessionId)
    });
  }

  public selectOption(option: string): void {
    this.audienceAIFormGroup.get('chatInput').patchValue(option);
    this.submitQuery();
  }

  public displayErrorMessage(chat: ChatMessage): boolean {
    const isLastChat = this.chatConversations.chat_history[this.chatConversations.chat_history.length - 1] === chat;
    return chat.response['response_type'] === 'ERROR' && isLastChat;
  }

  public onRegenerate() {
    const chatHistory = this.chatConversations.chat_history;
    if (chatHistory.length === 0) return;
    chatHistory.pop();
    this.audienceAIChatRequest.input_message = chatHistory[chatHistory.length - 1].content;
    this.performChat(this.audienceAIChatRequest);
    this.updateChatMessages(this.audienceAIChatRequest.input_message, UserType.HUMAN);
  }

  private setGenAIRequestIdsToChatMessage(genAIResponse: any, chatMessageConversation: ChatMessage) {
    const requestIds = ['include_genai_request_id', 'exclude_genai_request_id']
      .reduce((acc, key) => {
        const requestId = genAIResponse[key];
        if (requestId) acc[key] = requestId;
        return acc;
      }, {});

    if (Object.keys(requestIds).length > 0) {
      chatMessageConversation.genAIRequestIds = requestIds;
    }
  }

  private performChat(audienceAIChatRequest: AudienceAIChatRequest) {
    this.isQueryRunning = true;
    this.audienceAIChatService
      .performChat(this.contextId, audienceAIChatRequest, this.conversationId)
      .subscribe({
        next: async (response: AudienceAIChatResponse) => {
          if (response.response_type === AudienceAIResponseType.OK) {
            if (response.conversation_state.audience_count_job_id || response.conversation_state.preview_job_id) {
              this.jobType = response.conversation_state.audience_count_job_id ? 'Count' : 'Preview';
              await this.processJobResponse(response);
            } else {
              const contentMessage = this.updateChatContent(
                response.chat_message,
                response.conversation_state.audience_definition_id
              );
              const chatMessageConversation = new ChatMessage({
                role: UserType.ASSISTANT,
                content: contentMessage,
                options: response.options,
                additionalKwargs: {
                  sqlClause: response.response?.['combinedSqlClause']
                },
                response: { "response_type": response.response_type }
              });
              const genAIResponse = response.response?.['data'] ? response.response?.['data'] : response.response;
              if (genAIResponse) {
                this.setGenAIRequestIdsToChatMessage(genAIResponse, chatMessageConversation);
              }
              this.buildChatConversations(chatMessageConversation);
              const chatMessage = new ChatMessage({
                role: this.assistant,
                content: response.llm_output,
              });
              this.audienceAIChatRequest.chat_history.push(chatMessage);
              this.count = response.conversation_state.audience_count?.["count"];
            }
          } else if (response.response_type === AudienceAIResponseType.ERROR) {
            const errorHandlingMessage = 'Apologies, Server error occurred!';
            const chatMessageConversation = new ChatMessage({
              role: UserType.ASSISTANT,
              content: errorHandlingMessage,
              response: { "response_type": response.response_type }
            });
            this.buildChatConversations(chatMessageConversation);
            this.count = response.conversation_state.audience_count?.["count"];

            if (
              response.chat_message.includes(
                'Apologies! An unexpected error occurred'
              )
            ) {
              this.audienceAIChatRequest.chat_history.pop();
            } else {
              const chatMessage = new ChatMessage({
                role: this.assistant,
                content: response.llm_output,
                response: { "response_type": response.response_type }
              });
              this.audienceAIChatRequest.chat_history.push(chatMessage);
            }
          }
          this.scrollToBottom(this.chatContainer);
          const previewDataFormGroup = this.buildPreviewDataFormGroup(
            response.conversation_state.audience_preview
          );
          this.scrollToBottom(this.previewContainer);
          this.audienceAIFormGroup.setControl(
            'previewData',
            previewDataFormGroup
          );
          this.isQueryRunning = false;
          this.isUserInputEnable();
        },
        error: () => {
          this.audienceAIChatRequest.chat_history.pop();
          if (this.audienceAIChatRequest.chat_history.length < 2) {
            const errorHandlingMessage =
              'Apologies, Server error occurred! Can not initiate Audience AI';
            const chatMessage = new ChatMessage({
              role: UserType.ASSISTANT,
              content: errorHandlingMessage,
              response: { "response_type": 'ERROR' }
            });
            this.buildChatConversations(chatMessage);
          } else {
            const prevChatConversation =
              this.chatConversations.chat_history[
                this.chatConversations.chat_history.length - 2
              ];
            const errorHandlingMessage = `Apologies, Server Error Occurred!\n`;
            const chatMessage = new ChatMessage({
              role: UserType.ASSISTANT,
              content: errorHandlingMessage,
              options: prevChatConversation.options,
              genAIRequestIds: prevChatConversation.genAIRequestIds,
              response: { "response_type": 'ERROR' }
            });
            this.buildChatConversations(chatMessage);
          }
          this.scrollToBottom(this.chatContainer);
          this.scrollToBottom(this.previewContainer);
          this.isQueryRunning = false;
          this.isUserInputEnable();
        },
      });
  }

  public addSQLTags(sql: string) {
    return "```sql\n" + sql + "\n```";
  }

  private updateChatContent(message: string, audienceId: string) {
    let content = message;
    if (content.toLowerCase().includes('saved audience')) {
      let baseUrl = window.location.origin;
      const currentUrl = this.router.url;
      baseUrl = this.utilsService.environmentValue('domainName') === 'local' ? `${baseUrl}/app` : baseUrl;
      const audienceBuilderUrl = `${baseUrl}${currentUrl.split('ai-dailog')[0]}builder/edit/${audienceId}`;
      const link = `<a href="${audienceBuilderUrl}" target="_blank">Edit audience</a>`;
      content = content.replace('SAVED_AUDIENCE_CH', link);
    }
    return content;
  }

  private buildPreviewDataFormGroup(data: any): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({});
    for (const key in data) {
      if (data[key]) {
        formGroup.addControl(
          key,
          new UntypedFormControl({ value: data[key], disabled: false })
        );
      }
    }
    return formGroup;
  }

  public getTransformedText(chatMessage: string): string {
    const msg = chatMessage ?? '';
    console.log(">>>> MSG: ", msg);
    return msg;
  }

  public getSampleText(): string {
    const txt = `This is some sample sql query
\`\`\` sql
Profile.age > 10
AND Profile.gender < 'male1'
\`\`\`

This query is generated by llm
<b>S</b>ample
\`\`\`html
<p>Test</p>
\`\`\`
    `;
    return txt;
  }

  public getKeys(obj: any): string[] {
    return obj ? Object.keys(obj) : [];
  }

  public selectFeedback(userRating: number, chatIndex: number): void {
    this.chatConversations.chat_history[chatIndex].currentFeedbackRating = userRating;
    this.chatConversations.chat_history[chatIndex].isFeebackCommentGiven =
      false;
    if (this.chatConversations.chat_history.length - 1 === chatIndex) {
      this.scrollToBottom(this.chatContainer);
    }
  }

  private showFeedbackNotification(chat: ChatMessage, notificationStyle: string, notificationMessage: string) {
    this.notificationStyle = notificationStyle;
    this.notificationMessage = notificationMessage;
    chat.showNotification = true;
    setTimeout(() => chat.showNotification = false, 4000);
  }

  public submitFeedback(chatIndex: number): void {
    const chat = this.chatConversations.chat_history[chatIndex];
    for (const genId in chat.genAIRequestIds) {
      const generateFromTextRequest: AudienceDefinitionGenerateAIRating = {
        cabContextId: this.route.snapshot.paramMap.get('contextId'),
        genAIRequestId: chat.genAIRequestIds[genId],
        userRating: chat.currentFeedbackRating,
        userComments: this.audienceAIFormGroup.get('commentMessage').value,
      };
      this.builderService
        .generateAudienceDefinitionGenAIQueryRating(generateFromTextRequest)
        .subscribe({
          next: () => {
            this.audienceAIFormGroup.get('commentMessage').reset();
            this.showFeedbackNotification(chat, "success", this.feedbackSuccessMessage);
            chat.isFeebackCommentGiven = true;
            chat.previousFeedbackRating = chat.currentFeedbackRating;
          },
          error: () => {
            this.showFeedbackNotification(chat, "error", this.feedbackSuccessMessage);
            chat.isFeebackCommentGiven = true;
          },
        });
    }
  }

  private async processJobResponse(response: AudienceAIChatResponse) {
    try {
      const jobId = this.jobType === 'Preview'
        ? response.conversation_state.preview_job_id
        : response.conversation_state.audience_count_job_id;

      this.showCancel = true;
      const result: JobResponse = await lastValueFrom(this.audienceAIChatService
        .pollJobStatus(jobId, this.contextId));
      this.showCancel = false;

      if (result.status === 'SUCCESS') {
        if (this.jobType === 'Preview') {
          this.handlePreviewSuccess(response, result);
        } else {
          this.handleCountSuccess(response, result);
        }
        this.updateChatHistory(response, this.sample_data);
      } else {
        this.handleError(response);
      }
    } catch (error) {
      console.error('Error fetching job status:', error);
      this.handleError(response);
    }
  }

  handlePreviewSuccess(response: AudienceAIChatResponse, result: JobResponse): void {
    this.sample_data = result.result["data"];
    if (this.sample_data && this.sample_data.length > 0) {
      response.chat_message = `Here's a preview of profiles in this audience:<br><br>`;
    } else {
      response.chat_message = `Sorry, Did not find any Sample Data`;
    }
  }

  public cancelJob() {
    this.isCountCancelled = true;
    this.audienceAIChatService.cancelPolling();
    this.showCancel = false;
  }

  // Add a getter for headers if you don't already have one
  get tableHeaders(): string[] {
    return this.sample_data && this.sample_data.length > 0
      ? Object.keys(this.sample_data[0])
      : [];
  }

  private handleCountSuccess(response: AudienceAIChatResponse, result: JobResponse): void {
    this.count = result.result['count'];
    response.chat_message = `Great news! Your query includes <b>${this.count}</b> individuals.`;
    this.audienceAIChatService.updateCount(this.contextId, this.conversationId, this.count).subscribe();
  }

  private handleError(response: AudienceAIChatResponse): void {
    let responseType: any;
    if (this.jobType == 'Count') {
      response.chat_message = this.isCountCancelled ? this.countCancelledMessage : this.countErrorMessage;
      responseType = this.isCountCancelled ? "OK" : "ERROR";
    } else {
      response.chat_message = this.isCountCancelled ? this.previewCancelledMessage : this.previewErrorMessage;
      responseType = this.isCountCancelled ? "OK" : "ERROR";
    }
    const contentMessage = this.updateChatContent(response.chat_message, response.conversation_state.audience_definition_id);
    response.options.push("Try again");
    const chatMessageConversation = new ChatMessage({
      role: UserType.ASSISTANT,
      content: contentMessage,
      options: response.options,
      response: { "response_type": responseType }
    });
    this.buildChatConversations(chatMessageConversation);
    const chatMessage = new ChatMessage({ role: this.assistant, content: response.llm_output });
    this.audienceAIChatRequest.chat_history.push(chatMessage);
  }

  private updateChatHistory(response: AudienceAIChatResponse, sampleData?: any[]): void {
    const contentMessage = this.updateChatContent(response.chat_message, response.conversation_state.audience_definition_id);
    const chatMessageConversation = new ChatMessage({
      role: UserType.ASSISTANT,
      content: contentMessage,
      options: response.options,
      additionalKwargs: {
        previewId: response.conversation_state.preview_job_id,
        sqlClause: response.response?.['combinedSqlClause'],
        sampleData: sampleData ? [...sampleData] : [],
        tableHeaders: sampleData && sampleData.length > 0 ? Object.keys(sampleData[0]) : []
      },
      response: { "response_type": response.response_type }
    });
    this.buildChatConversations(chatMessageConversation);
    const chatMessage = new ChatMessage({ role: this.assistant, content: response.chat_message });
    this.audienceAIChatRequest.chat_history.push(chatMessage);
  }

  copyToClipboard(): void {
    navigator.clipboard.writeText(
      this.audienceAIFormGroup.get('previewData').value.audience_query
    );
  }

  openConfirmationPopup(): void {
    this.confirmationPopup.launchAlertModal();
  }

  handleConfirm(): void {
    this.clearChat();
  }

  handleCancel(): void {
    return;
  }

  onChatInputChange(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    this.isUserInputEnable();
    this.adjustInputHeight(textarea);
  }

  public showToast(item: NotificationComponent): void {
    item.show();
  }

  public isFeedbackMenuShown(genAIRequestId: any): boolean {
    return genAIRequestId ? true : false;
  }

  public isFeedbackSelected(index: number, rating: number): boolean {
    return this.chatConversations.chat_history[index].currentFeedbackRating === rating
      ? true
      : false;
  }

  public isCommentAreaShown(index: number): boolean {
    return (
      this.chatConversations.chat_history[index].currentFeedbackRating !== 0 &&
      this.chatConversations.chat_history[index].isFeebackCommentGiven === false
    );
  }

  public close(index: number) {
    const chat = this.chatConversations.chat_history[index]
    chat.isFeebackCommentGiven = true;
    chat.currentFeedbackRating = chat.previousFeedbackRating;
  }

  public adjustInputHeight(textarea: HTMLTextAreaElement): void {
    textarea.style.height = 'fit-content';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  public get audienceAIFormGroupPreviewDataHasSomeValues() {
    const formValues = this.audienceAIFormGroup.get('previewData').value;
    const controls = Object.keys(formValues);
    return controls.length && controls.some(c => formValues[c]?.length);
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        return;
      } else {
        event.preventDefault();
        this.submitQuery();
      }
      const textarea = event.target as HTMLTextAreaElement;
      textarea.style.height = 'fit-content';
    }
  }

  public isUserInputEnable(): void {
    const inputValue = this.audienceAIFormGroup.get('chatInput');
    this.isUserInputValid = this.isQueryRunning || !inputValue || !inputValue.value || inputValue.value.trim().length === 0;
  }

}
